import { CarouselStrategy, ICreativeComponent } from 'components';
import {
    EventTrackingMethods,
    EventTypes,
    NativeAssetId,
    NativeRequestBuilder,
    NativeResponseToProductInformation,
    type IBid,
    type IBidRequest,
    type IBidResponse,
    type ICallbacks,
    type ICompiledPublisherConfiguration,
    type INativeResponse,
    type IProductContext,
    type IProductInformation
} from 'models';
import type { IConfigurationService, IProductInformationService } from 'services';
import { renderTemplate, DecodeAdm, RenderCommerceMediaURL } from 'utils';
import template from './SurfProductCard.skeleton.component.hbs';
import style from './SurfProductCard.skeleton.component.scss';
import { RecommenderType } from 'services/';

/**
 * A creative component which renders a product card
 */
export class SurfProductCard extends ICreativeComponent {
    private _configService?: IConfigurationService;
    private _productInformationService?: IProductInformationService;

    private _productTemplateDelegate: Promise<HandlebarsTemplateDelegate<IProductContext> | null> =
        Promise.resolve(null);

    private _productCardStylesheet: Promise<string | null> = Promise.resolve(null);
    private _config: Promise<ICompiledPublisherConfiguration | null> = Promise.resolve(null);
    private _recommendedProductCard: Promise<IProductInformation | null> = Promise.resolve(null);
    private _strategy: CarouselStrategy = CarouselStrategy.HYBRID;
    private _recommend: RecommenderType = RecommenderType.TOP_ALL;

    private _catalog: boolean = true;

    public async init(): Promise<void> {
        await super.init();
        this.logger.debug('SurfBanner.constructor initializing');

        this._catalog = this.getAttribute('catalog') === 'true';

        this._configService = globalThis.surfside.config;
        this._productInformationService = globalThis.surfside.productInformationService;

        this._config = this._configService.getConfiguration(this.accountId, this.siteId);
        this._productTemplateDelegate = this._config.then(config => {
            const productCardTemplate = config?.templates.productCardTemplate;
            if (productCardTemplate === null) {
                this.logger.error(
                    'SurfCommerceMediaBanner.constructor productCardTemplate is null or empty',
                    'cannot compile Handlebars template!'
                );
                return null;
            }

            return productCardTemplate ?? null;
        });
        this._productCardStylesheet = this._config.then(config => {
            return config?.templates.productCardStylesheet ?? null;
        });
        this._recommend = this.getEnumOrNull('recommend', RecommenderType) ?? RecommenderType.TOP_ALL;
        this._strategy = this.getEnumOrNull('strategy', CarouselStrategy) ?? CarouselStrategy.HYBRID;

        if (this._strategy?.toLowerCase() !== CarouselStrategy.SPONSORED.toLowerCase()) {
            this._recommendedProductCard = globalThis.surfside.recommenderService.request({
                type: this._recommend,
                placementId: this.placementId,
                accountId: this.accountId,
                locationId: this.locationId,
                siteId: this.siteId
            });
        } else {
            this._recommendedProductCard = Promise.resolve(null); // set it to null otherwise
        }

        /* Required to avoid Uncaught Error (in promise) errors from spamming the logs */
        Promise.allSettled([this._config, this._productTemplateDelegate, this._recommendedProductCard])
            .then((promises) => {
                for (const p of promises) {
                    if (p.status === 'rejected') {
                        this.logger.error('SurfProductCard.constructor promise rejected', p.reason);
                        this.fail();
                    }
                }
            })
            .catch((err) => {
                this.logger.error('SurfProductCard.constructor error', err);
                this.fail();
            });

        this.style.display = 'flex';
        this.style.flexDirection = 'column';
        this.style.height = '100%';
    }

    public override async connectedCallback(): Promise<void> {
        this.shadow.innerHTML = `<style>${style}</style>${template({})}`;

        super.connectedCallback()
            .then(() => { this.logger.debug('SurfProductCard.connectedCallback done'); })
            .catch((err) => { this.logger.error('SurfProductCard.connectedCallback error', err); });
    }

    public async makeRequest(): Promise<IBidRequest | null> {
        if (this._strategy === CarouselStrategy.RECOMMENDED) {
            return null; // null request for recommended only
        }
        this.logger.debug('SurfProductCard.makeRequest');
        this.logger.debug('SurfProductCard.makeRequest creating native request');
        const requestBuilder = (new NativeRequestBuilder())
            .withSponsored()
            .withDescription()
            .withRating()
            .withPrice()
            .withSalePrice()
            .withDisplayUrl()
            .withCtaText()
            .withStrain()
            .withTHC()
            .withCBD()
            .withCategory();

        if (this._catalog) {
            this.logger.debug('SurfProductCard.makeRequest adding catalog data');
            requestBuilder.withMetaProduct();
        }

        const nativeRequest = requestBuilder.build();
        this.logger.debug('SurfProductCard.makeRequest nativeRequest', nativeRequest);

        const request: IBidRequest = {
            id: this.placementId,
            imp: [
                {
                    id: '123',
                    native: {
                        request: JSON.stringify(nativeRequest)
                    }
                }
            ]
        };
        if (globalThis.debug) {
            this.logger.debug('SurfProductCard.makeRequest test mode');
            request.test = 1;
        }
        this.logger.debug('SurfProductCard.makeRequest request', request);
        return request;
    }

    public reauction(): void {
        this.logger.debug('SurfProductCard.reauction reauctioning not enabled for product cards');
    }

    public async render(bidResponse: IBidResponse, bid: IBid): Promise<void> {
        const encodedAdm = bid.adm;
        this.logger.debug('SurfProductCard.render adm', encodedAdm);
        if (encodedAdm === undefined) {
            this.fail();
            throw new Error('SurfProductCard.render adm is undefined');
        }
        const decodedAdm = DecodeAdm(encodedAdm);
        this.logger.debug('SurfProductCard.render adm', decodedAdm);
        this.logger.debug('SurfProductCard.render parsing native response to product info');
        const nativeResponse: INativeResponse = JSON.parse(decodedAdm) as INativeResponse;
        this.logger.debug('SurfProductCard.render nativeResponse', nativeResponse);
        const productInformation = NativeResponseToProductInformation(nativeResponse);
        this.logger.debug('SurfProductCard.render productInformation', productInformation);

        this.logger.debug('SurfProductCard.render waiting for productTemplateDelegate to be ready');
        const productTemplateDelegate = await this._productTemplateDelegate;
        if (productTemplateDelegate === null) {
            this.fail();
            throw new Error('SurfProductCard.render productTemplateDelegate is null');
        }

        const productCardStylesheet = await this._productCardStylesheet;
        this.logger.debug('SurfProductCard.render productTemplateDelegate is ready');

        this.logger.debug('SurfProductCard.render waiting for config to be ready');
        const config = await this._config;
        if (config === null) {
            this.fail();
            throw new Error('SurfProductCard.render config is null');
        }
        this.logger.debug('SurfProductCard.render config is ready');

        let catalogProduct: IProductInformation = {
            image: null,
            price: null,
            salePrice: null,
            thc: null,
            name: null,
            brandName: null,
            productType: null,
            details: null,
            cta: null,
            clickthroughUrl: null,
            winUrl: null,
            pixelUrl: null,
            cbd: null,
            strain: null
        };
        if (this._catalog) {
            const productId = nativeResponse.native.assets
                ?.find(asset => asset.id === NativeAssetId.SURF_META_PRODUCT)
                ?.data
                ?.value;
            if (productId !== undefined) {
                this.logger.debug('SurfProductCard.render catalog product id', productId);
                const catalogProductOrUndefined = await this._productInformationService?.getProductByNativeId(
                    this.accountId,
                    this.siteId,
                    this.locationId,
                    productId
                );
                if (catalogProductOrUndefined === undefined) {
                    throw new Error('SurfProductCard.render catalog product is undefined');
                }
                catalogProduct = catalogProductOrUndefined;
                this.logger.debug('SurfProductCard.render catalog product', catalogProduct);
            }
        }

        const trackerPixels = nativeResponse.native.eventtrackers
            ?.filter(e =>
                e.method === EventTrackingMethods.IMG &&
                e.event === EventTypes.IMP &&
                e.url !== undefined
            )
            .map(t => `${t.url}`) ?? [];

        const trackerScripts = nativeResponse.native.eventtrackers
            ?.filter(e =>
                e.method === EventTrackingMethods.JS &&
                e.event === EventTypes.IMP &&
                e.url !== undefined
            )
            .map(t => `<script type="text/javascript" src="${t.url}"></script>`) ?? [];

        await this._renderProduct(
            productTemplateDelegate,
            productCardStylesheet,
            config,
            productInformation,
            catalogProduct,
            trackerPixels,
            trackerScripts,
            bid.nurl,
            true
        );
    }

    public async fallback(): Promise<void> {
        if (this._recommendedProductCard === null) {
            this.logger.error('SurfProductCard.fallback error. Recommended product card is null');
            this.remove();
            return;
        }
        const recommendedProductInformation = await this._recommendedProductCard;
        if (recommendedProductInformation === null) {
            this.logger.debug(
                'SurfProductCard.fallback recommendedProductInformation is null. ' +
                'Product card is probably sponsored only'
            );
            this.remove();
            return;
        }
        let product: IProductInformation;
        try {
            try {
                product = recommendedProductInformation;
                this.logger.debug('SurfProductCard.fallback product', product);
            } catch (err) {
                this.logger.error('SurfProductCard.fallback error', err);
                this.remove();
                return;
            }
        } catch (err) {
            this.logger.error('SurfProductCard.fallback error. Failed to fetch recommender service', err);
            this.remove();
            return;
        }
        const productTemplateDelegate = await this._productTemplateDelegate;
        if (productTemplateDelegate === null) {
            this.logger.error('Failed to load product template delegate from config');
            this.remove();
            return;
        }
        const productCardStylesheet = await this._productCardStylesheet;

        const config = await this._config;
        if (config === null) {
            this.logger.error('Failed to load config');
            this.remove();
            return;
        }

        await this._renderProduct(
            productTemplateDelegate,
            productCardStylesheet,
            config,
            undefined,
            product
        );
    }

    private _appendCallbacks(callbacks: ICallbacks, product: IProductInformation): void {
        const found: Element[] = [];

        function walkChildren(node: Node): void {
            if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as Element;
                if ([...element.attributes].some(attr => attr.nodeName.startsWith('_'))) {
                    found.push(element);
                }
            } else {
                /* Don't bother checking child nodes of non-elements */
                return;
            }
            node.childNodes.forEach(child => {
                walkChildren(child);
            });
        };
        this.shadow.childNodes.forEach(child => {
            walkChildren(child);
        });

        for (const bound of found) {
            const boundAttributes = [...bound.attributes].filter(attr => attr.nodeName.startsWith('_'));
            for (const attr of boundAttributes) {
                const eventName = attr.nodeName.replaceAll('_', '');
                const callbackName = attr.value;

                if (callbacks[callbackName] === undefined) {
                    this.logger.error(`SurfProductCard._appendCallbacks callback for ${callbackName} is undefined `);
                    continue;
                }
                bound.addEventListener(eventName, (e) => {
                    callbacks[callbackName](e, product);
                });
            }
        }
    }

    private async _renderProduct(
        productTemplateDelegate: HandlebarsTemplateDelegate<IProductContext>,
        productCardStylesheet: string | null,
        config: ICompiledPublisherConfiguration,
        productInformation: IProductInformation | undefined,
        catalogProduct: IProductInformation,
        trackerPixels: string[] = [],
        trackerScripts: string[] = [],
        nurl: string | undefined = undefined,
        sponsored: boolean = false
    ): Promise<void> {
        const html = renderTemplate(productTemplateDelegate, productInformation, config, catalogProduct);
        this.logger.debug('SurfProductCard.render html', html);
        this.shadow.innerHTML = html + `<div class="sponsored-card">${sponsored ? 'Sponsored' : '&nbsp;'}</div>`;

        if (productCardStylesheet !== null) {
            this.shadow.innerHTML += `<style>${productCardStylesheet}</style>`;
        }

        for (const pixel of trackerPixels) {
            this.shadow.innerHTML += pixel;
        }

        /* There's only one js tracker available, but we'll keep the loop for consistency
         * and in case we need to add more in the future */
        for (const script of trackerScripts) {
            this.shadow.innerHTML += script;
        }

        if (nurl !== undefined) {
            const winPixel = document.createElement('img');
            winPixel.src = nurl;
            winPixel.width = 1;
            winPixel.height = 1;
            winPixel.style.display = 'none';
            this.shadow.appendChild(winPixel);
        } else {
            this.logger.error('SurfProductCard.render winUrl is null');
        }
        this.logger.debug('SurfProductCard.render productCardRendered event dispatched');
        this.logger.debug(`surf-carousel[placement-id=${this.placementId.split('/')[0]}]`);
        const grandParent = document.querySelector(`surf-carousel[placement-id='${this.placementId.split('/')[0]}']`);
        this.logger.debug('SurfProductCard.render grandParent', grandParent);

        this._appendCallbacks(config.templates.javascriptCallbacks, catalogProduct);

        grandParent?.dispatchEvent(new CustomEvent<IProductInformation>('productCardRendered', {
            detail: productInformation ?? catalogProduct
        }));

        const clickthrough = this.shadow.querySelector('a[href*=\\{SURF_COMMERCE_URL\\}]');
        if (clickthrough === null) {
            return;
        }
        const clickthroughUrl = clickthrough.getAttribute('href');
        if (clickthroughUrl === null) {
            this.logger.error('SurfProductCard.render clickthroughUrl is null');
            this.remove();
            return;
        }

        if (this._configService === undefined) {
            throw new Error('SurfProductCard.render _configService is undefined');
        }

        const rendered = await RenderCommerceMediaURL(
            this.accountId,
            this.siteId,
            this.locationId,
            productInformation ?? catalogProduct,
            catalogProduct,
            this._configService,
            this.logger
        );

        if (rendered === null) {
            this.logger.error('SurfProductCard.render renderedClickthrough is null. Cannot render banner');
            this.remove();
            return;
        }

        clickthrough.setAttribute('href', clickthroughUrl.replace('{SURF_COMMERCE_URL}', rendered));
    }

    public fail(): void {
        const grandParent = document.querySelector(`surf-carousel[placement-id='${this.placementId.split('/')[0]}']`);
        grandParent?.dispatchEvent(new CustomEvent<null>('productCardFailed', {
            detail: null
        }));
    }
}
