import {
    type EventTrackingMethods,
    type IProductInformation,
    type NativeDataTypes,
    NativeAssetId,
    NativeImageTypes,
    EventTypes
} from 'models';

export interface INativeResponse {
    native: INativeResponseDetails;
}

export interface INativeResponseDetails {
    ver?: string;
    assets?: IAssetResponseObject[];
    assetsurl?: string;
    dcourl?: string;
    link: ILinkResponseObject;
    imptrackers?: string[];
    jstracker?: string;
    eventtrackers?: IEventTrackerResponseObject[];
    privacy?: string;
    ext?: any;
}

export interface IAssetResponseObject {
    id?: number;
    required?: number;
    title?: ITitleResponseObject;
    img?: IImageResponseObject;
    data?: IDataResponseObject;
    video?: IVideoResponseObject;
    link?: ILinkResponseObject;
    ext?: any;
}

export interface ITitleResponseObject {
    text: string;
    len?: number;
    ext?: any;
}

export interface IImageResponseObject {
    type: NativeImageTypes;
    url: string;
    w?: number;
    h?: number;
    ext?: any;
}

export interface IDataResponseObject {
    type?: NativeDataTypes;
    value: string;
    ext?: any;
}

export interface IVideoResponseObject {
    vasttag: string;
}

export interface ILinkResponseObject {
    url: string;
    clicktrackers?: string[];
    fallback?: string;
    ext?: any;
}

export interface IEventTrackerResponseObject {
    event: EventTypes;
    method: EventTrackingMethods;
    url?: string;
    customdata?: any;
    ext?: any;
}

function findDataAssetByType(assets: IAssetResponseObject[] | undefined, id: NativeAssetId): string | null {
    return assets?.find((asset) => asset.id === id)?.data?.value ?? null;
}

export function NativeResponseToProductInformation(
    native: INativeResponse
): IProductInformation {
    return {
        image: native.native.assets?.find((asset) => asset.img?.type === NativeImageTypes.MAIN)?.img?.url ?? null,
        name: native.native.assets?.find((asset) => asset.title !== undefined)?.title?.text ?? null,

        price: findDataAssetByType(native.native.assets, NativeAssetId.PRICE),
        salePrice: findDataAssetByType(native.native.assets, NativeAssetId.SALEPRICE),
        thc: findDataAssetByType(native.native.assets, NativeAssetId.SURF_THC),
        cbd: findDataAssetByType(native.native.assets, NativeAssetId.SURF_CBD),
        strain: findDataAssetByType(native.native.assets, NativeAssetId.SURF_STRAIN),
        brandName: findDataAssetByType(native.native.assets, NativeAssetId.SPONSORED),
        productType: findDataAssetByType(native.native.assets, NativeAssetId.SURF_CATEGORY),
        details: findDataAssetByType(native.native.assets, NativeAssetId.DESC),
        cta: findDataAssetByType(native.native.assets, NativeAssetId.CTATEXT),

        clickthroughUrl: native.native.link?.url ?? null,
        winUrl: native.native.eventtrackers?.find((event) => event.event === EventTypes.SURF_WIN)?.url ?? null,
        pixelUrl: native.native.eventtrackers?.find((event) => event.event === EventTypes.IMP)?.url ?? null
    };
};
