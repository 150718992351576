export interface IDevice {
    make?: string;
    model?: string;
    os?: string;
    osv?: string;
};

export function TryGetDeviceInfo(): IDevice | null {
    const ua = navigator.userAgent;
    const uaLower = ua.toLowerCase();
    if (uaLower.includes('android')) {
        return tryGetAndroidDeviceInfo(ua);
    } else if (uaLower.includes('iphone') || uaLower.includes('ipad')) {
        return tryGetiPhoneDeviceInfo(ua);
    } else if (uaLower.includes('windows nt')) {
        return {
            make: 'Microsoft',
            model: 'Windows',
            os: 'Windows',
            osv: /Windows NT (?<osv>[0-9.]+)/ig.exec(ua)?.groups?.osv
        };
    } else if (uaLower.includes('macintosh')) {
        return {
            make: 'Apple',
            model: 'Macintosh',
            os: 'MacOS',
            osv: /Mac OS X (?<osv>[0-9_]+)/ig.exec(ua)?.groups?.osv?.replaceAll('_', '.')
        };
    } else {
        return null;
    }
}

function tryGetAndroidDeviceInfo(ua: string): IDevice | null {
    const match = /^[^\s]+\s\((?<Linux>.*);\s*(?<osv>[^)]+);\s*(?<Model>[^)]+)\)/ig.exec(ua);
    const osv = match?.groups?.osv;
    const model = match?.groups?.Model;
    let make: string | undefined;
    if (model !== undefined) {
        if (model.includes('Pixel')) {
            make = 'Google';
        } else if (model.includes('SM-')) {
            make = 'Samsung';
        } else if (model.startsWith('LG-')) {
            make = 'LG';
        } else if (model.startsWith('moto')) {
            make = 'Motorola';
        } else if (model.startsWith('Redmi')) {
            make = 'Xiaomi';
        } else {
            make = 'Android';
        }
    }

    return {
        os: 'Android',
        osv,
        model,
        make
    };
}

function tryGetiPhoneDeviceInfo(ua: string): IDevice | null {
    const match = /^[^\s]+\s*\((?<Device>[^;]+);\s(?<Model>[^;)]+[;)])\s(?<ModelExt>[^;)]+[;)])?/ig.exec(ua);
    const make = 'Apple';
    const device = match?.groups?.Device;
    let model: string | undefined;
    if (device !== undefined) {
        if (device.toLowerCase().includes('ipad')) {
            model = 'iPad';
        } else {
            model = 'iPhone';
        }
    }

    const modelGroup = match?.groups?.Model;
    /* Newer Device */
    const os: string = 'iOS';
    let osv: string | undefined;

    let osvSearch = modelGroup;

    if (osvSearch === 'U') {
        // In newer iOS devices, the 2nd part of the device info is "U", so we
        // need to use the 3rd section, which I call "Model Ext" or "model extension"
        osvSearch = match?.groups?.ModelExt;
    }
    if (osvSearch !== undefined) {
        const extMatch = /OS (?<ver>[0-9_]+)/ig.exec(osvSearch);
        osv = extMatch?.groups?.ver;
    }
    return {
        make,
        model,
        os,
        osv
    };
};
