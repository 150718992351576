import type { ICompiledPublisherConfiguration } from 'models';
import type { ILoggerService } from 'services';
import { GetParameter } from 'utils';

export abstract class ISurfsideComponent extends HTMLElement {
    /** The Account ID of the site. Should be the same for all components on the page */
    public accountId: string = '00000';

    /** The channel ID of the site. Should be the same for all components on the page */
    public channelId: string = '00000';

    /** The Site ID of the site. Should be the same for all components on the page */
    public siteId: string = '00000';

    /** The Placement ID. Should be unique for each component on the page. Generated by the surfside platform */
    public placementId: string = '00000';

    /** The Zone ID. This is a more human-readable description of where the placement is on the page (e.g: ) */
    public zone: string | null = null;

    /** The Location ID. Some ecommerce platforms will have multiple physical locations, so you can have one site ID but
     * multiple location IDs. This is useful for targeting
     */
    public locationId: string = '00000';

    /** Keywords to target. */
    public keywords: string[] | null = null;

    /* This shadow to write the creative HTML to */
    protected shadow: ShadowRoot = this.attachShadow({ mode: 'open' });

    protected logger: ILoggerService = globalThis.surfside.logger;

    protected configuration: Promise<ICompiledPublisherConfiguration | null> = Promise.resolve(null);

    public async init(): Promise<void> {
        this.logger.debug('ISurfsideComponent.constructor initializing');

        /* !!!!!!!!!!!!!!!!!!!!!!!!! XSS WARNING AHEAD !!!!!!!!!!!!!!!!! */
        /* !!!!! Do not EVER URIDecode this, it is a security risk !!!!! */
        const accountIdOverride = GetParameter('surf_account_id');
        /* !!!!! Do not EVER URIDecode this, it is a security risk !!!!! */
        const siteIdOverride = GetParameter('surf_site_id');
        /* !!!!! Do not EVER URIDecode this, it is a security risk !!!!! */
        const locationIdOverride = GetParameter('surf_location_id');
        /* !!!!!!!!!!!!!!!!!!!!!!!!! END XSS WARNING !!!!!!!!!!!!!!!!!!! */

        this.accountId = accountIdOverride ?? this.attributeOrError('account-id');
        this.channelId = this.attributeOrError('channel-id');
        this.siteId = siteIdOverride ?? this.attributeOrError('site-id');
        this.placementId = this.attributeOrError('placement-id');
        this.zone = this.getAttribute('zone');
        this.locationId = locationIdOverride ?? this.attributeOrError('location-id');
        this.keywords = this.getAttribute('keywords')?.split(',').map(keyword => keyword.trim()) ?? null;
        this.configuration = globalThis.surfside.config.getConfiguration(this.accountId, this.siteId);
        this.logger.debug('ISurfsideComponent.constructor initialized', this);
    }

    /* ==== Helpers ==== */
    /**
     * Get an attribute from the component or throw an error if it is not present.
     * @param name The name of the attribute to get
     * @returns The value of the attribute
     */
    protected attributeOrError(name: string): string {
        const value = this.getAttribute(name);
        if (value !== null) {
            return value;
        }
        throw new Error(`Surf-ads: attribute "${name}" is required on creative tag`);
    }

    /**
     * Converts a string to a number, or null if the string is null.
     * @param value The string to convert
     * @returns The number, or null if the string is null
     */
    protected numberOrNull(value: string | null): number | null {
        if (value !== null) {
            const num = parseInt(value);
            if (!isNaN(num)) {
                return num;
            }
        }
        return null;
    }

    protected numberOrError(value: string | null): number {
        const num = this.numberOrNull(value);
        if (num !== null) {
            return num;
        }
        throw new Error(`Surf-ads: attribute must be a number`);
    }

    protected appendStylesheet(style: string): void {
        const styleElement = document.createElement('style');
        styleElement.textContent = style;
        this.shadow.appendChild(styleElement);
    }

    protected mergeChildren(wrapper: Element): void {
        for (const child of wrapper.children) {
            this.shadow.appendChild(child);
        }
    }

    protected getEnumOrNull<T>(name: string, enumType: T): T[keyof T] | null {
        const value = this.getAttribute(name);
        if (
            value !== null &&
            Object.values(enumType as Record<string, string>)
                .map(key => key.toLowerCase())
                .includes(value)
        ) {
            return value as T[keyof T];
        }
        return null;
    }

    protected getEnumOrError<T>(name: string, enumType: T): T[keyof T] {
        const value = this.getAttribute(name);
        if (value !== null && value in (enumType as any)) {
            return value as T[keyof T];
        }
        throw new Error(
            `Surf-ads: attribute "${name}" must be one of ${Object.keys((enumType as object)).join(', ')}`
        );
    }
}
