import { Environment, HttpStatus } from 'Constants';
import type { ICompiledPublisherConfiguration, ICompiledPublisherConfigurationDTO } from 'models';
import { IConfigurationService, type ILoggerService } from 'services';

interface S3EnvironmentSettings {
    cdn: string;
    keyPrefix: string;
};

/* These are different hardcoded settings for different environments */
const PROD_SETTINGS: S3EnvironmentSettings = {
    cdn: '//r.surfside.io',
    keyPrefix: 'publisher'
};

const STAGING_SETTINGS: S3EnvironmentSettings = {
    cdn: '//surf-creative-assets-test.s3.us-east-1.amazonaws.com',
    keyPrefix: 'publisher'
};

const LOCAL_SETTINGS: S3EnvironmentSettings = {
    cdn: '//localhost:9001/local-config-service',
    keyPrefix: 'publisher'
};

/**
 * Configuration service that fetches configuration from S3, or a simple local server when set to LOCAL mode
 */
export class S3ConfigurationService extends IConfigurationService {
    private readonly _settings: S3EnvironmentSettings;

    /* Used to cache the result since the value won't change after an initial page load */
    private _config: ICompiledPublisherConfiguration | null = null;
    private _configPromise: Promise<ICompiledPublisherConfiguration | null> | undefined;

    constructor(private readonly _environment: Environment, logger: ILoggerService) {
        super(logger);
        /* Set variables based on environment */
        this.logger.debug('S3ConfigurationService.constructor initializing', _environment);
        switch (_environment) {
            case Environment.PROD: {
                this._settings = PROD_SETTINGS;
                break;
            }
            case Environment.STAGING: {
                this._settings = STAGING_SETTINGS;
                break;
            }
            default: {
                this._settings = LOCAL_SETTINGS;
                break;
            }
        }
        this.logger.debug('S3ConfigurationService.constructor initialized', _environment);
    }

    private async _requestConfiguration(
        accountId: string,
        siteId: string
    ): Promise<ICompiledPublisherConfiguration | null> {
        /* Build config URL */
        const configUrl =
            new URL(
                `${window.location.protocol}${this._settings.cdn}/` +
                [
                    accountId,
                    siteId,
                    'config.json'
                ].join('/')
            );

        try {
            this.logger.debug('S3ConfigurationService.getConfiguration fetching config', configUrl);
            const configResponse = await fetch(configUrl);
            this.logger.debug('S3ConfigurationService.getConfiguration fetched config', configResponse);

            /* 200 OK */
            if (configResponse.ok) {
                this.logger.debug('S3ConfigurationService.getConfiguration config is ok');
                const dto = await configResponse.json() as ICompiledPublisherConfigurationDTO;
                this._config = await this.mapConfig(dto);
                this.logger.debug('S3ConfigurationService.getConfiguration returning config', this._config);
                if (this._config !== null) {
                    /* Valid config, return it */
                    return this._config;
                } else {
                    /* Empty config file */
                    this.logger.error("Configuration service returned 'OK' but no configuration was returned");
                    return null;
                }
            }

            /* Handle specific status codes */
            switch (configResponse.status) {
                case HttpStatus.NOT_FOUND: {
                    this.logger.error(`Configuration not found at ${configUrl.toString()}`);
                    break;
                }
                case HttpStatus.FORBIDDEN:
                case HttpStatus.UNAUTHORIZED: {
                    this.logger.error(`Unauthorized to access configuration at ${configUrl.toString()}`);
                    break;
                }
                default: {
                    this.logger.error(
                        `Failed to get configuration at ${configUrl.toString()}`,
                        `Got status code ${configResponse.status}`
                    );
                    if (this._environment === Environment.LOCAL) {
                        this.logger.error('Did you forget to start the local config service? (serve -l 9001 -C)');
                    }
                }
            }
        } catch (err) {
            /* This error is most likely a JSON deserialization error. i.e: the config file was not valid JSON */
            /* This is an error and not a warning because it indicates the site WANTED a config but failed to get it */
            this.logger.error('Failed to get configuration, Unknown Error: ', err);
            if (this._environment === Environment.LOCAL) {
                this.logger.error('Did you forget to start the local config service? (serve -l 9001 -C)');
            }
        } finally {
            /* This is a warning because not every site needs a config. If it doesn't exist, the site will still work */
            if (this._config === null) {
                this.logger.warn('Failed to get configuration. Commerce media will not work but static ads will');
            }
        }
        return null;
    }

    async getConfiguration(accountId: string, siteId: string): Promise<ICompiledPublisherConfiguration | null> {
        this.logger.debug('S3ConfigurationService.getConfiguration', accountId, siteId);
        /* If we have already fetched the configuration, return it */
        if (this._config !== null) {
            this.logger.debug('S3ConfigurationService.getConfiguration returning cached config');
            return this._config;
        }
        if (this._configPromise === undefined) {
            this._configPromise = this._requestConfiguration(accountId, siteId);
            return await this._configPromise;
        } else {
            return await this._configPromise;
        }
    }
}
