import type { ILoggerService } from './ILoggerService';

type ResolvedHandler<TResponse> = (response: TResponse) => void;
type RejectedHandler<TErrorResponse> = (error: TErrorResponse | Error) => void;

interface IBidRequestDebouncer<TRequest, TResponse, TErrorResponse> {
    timeout: number | undefined;
    requests: TRequest[];
    responseHandlers: Map<string, [ResolvedHandler<TResponse>, RejectedHandler<TErrorResponse>]>;
};

export abstract class IDebouncedMulticastRequester<TRequest, TResponse, TServiceResponse, TErrorResponse> {
    protected readonly requestDebouncer: Map<string, IBidRequestDebouncer<TRequest, TResponse, TErrorResponse>>;

    constructor(protected readonly logger: ILoggerService, private readonly _requestDebounceTimeout = 1) {
        this.logger.debug('IBidderService instantiated');
        this.requestDebouncer = new Map();
    }

    public async request(request: TRequest): Promise<TResponse> {
        const requestId = this.getRequestId(request);
        const debouncer = this.requestDebouncer.get(requestId) ?? {
            timeout: undefined,
            requests: new Array<TRequest>(),
            responseHandlers: new Map()
        };

        if (debouncer.timeout !== undefined) {
            window.clearTimeout(debouncer.timeout);
        }

        debouncer.timeout = window.setTimeout(() => {
            const request = this.collectRequests(debouncer.requests);
            this.logger.debug('Request debouncer sent request', requestId);
            this.sendRequestToService(request)
                .then(async (response: TServiceResponse) => {
                    const responseId = this.getResponseId(request, response);
                    const debouncer = this.requestDebouncer.get(responseId);
                    if (debouncer === undefined) {
                        this.logger.error(
                            'Request debouncer ID not found; response ID not associated with a request ID',
                            responseId
                        );
                    }
                    const responses = await this.collectResponses(request, response);

                    for (const [id, finalResponse] of responses) {
                        const keys = [...debouncer?.responseHandlers.keys() ?? []];
                        const [resolve, reject] = debouncer?.responseHandlers.get(id) ?? [];
                        if (resolve === undefined) {
                            this.logger.error('Response handler not found', responseId, id, keys);
                            continue;
                        }
                        if (finalResponse === undefined && reject !== undefined) {
                            this.logger.error('Request debouncer response rejected', responseId, id);
                            reject(new Error('Response not found'));
                        } else {
                            this.logger.info('Request debouncer response resolved', responseId, id);
                            resolve(finalResponse);
                        }
                    }
                    const resolvedIds = responses.map(r => r[0]);
                    const unresolvedIds = ([...debouncer?.responseHandlers.keys() ?? []]).filter(handlerKey =>
                        !resolvedIds.includes(handlerKey)
                    );

                    for (const unresolvedId of unresolvedIds) {
                        const [, unresolvedHandler] = debouncer?.responseHandlers.get(unresolvedId) ?? [];
                        if (unresolvedHandler !== undefined) {
                            this.logger.info(
                                'Request debouncer response rejected; no data for inner request ID',
                                '(Multicast returned fewer responses than requested)',
                                responseId,
                                unresolvedId
                            );
                            unresolvedHandler(this.serviceResponseToError(request, response));
                        }
                    }

                    this.requestDebouncer.delete(requestId);
                })
                .catch(err => {
                    const debouncer = this.requestDebouncer.get(requestId);
                    if (debouncer === undefined) {
                        this.logger.error('Request debouncer not found for ID', requestId);
                        return;
                    }
                    for (const [, reject] of debouncer.responseHandlers.values()) {
                        reject(err as Error);
                    }
                });
        }, this._requestDebounceTimeout);

        debouncer.requests.push(request);
        this.logger.debug('Request debouncer request added', requestId);
        this.requestDebouncer.set(requestId, debouncer);
        return await new Promise<TResponse>((resolve, reject) => {
            this.logger.debug('Request debouncer promise created', requestId, this.getRequestInnerId(request));
            debouncer.responseHandlers.set(this.getRequestInnerId(request), [resolve, reject]);
        });
    }

    abstract sendRequestToService(request: TRequest): Promise<TServiceResponse>;
    abstract collectRequests(requests: TRequest[]): TRequest;
    abstract collectResponses(request: TRequest, response: TServiceResponse): Promise<Array<[string, TResponse]>>;

    abstract getRequestId(request: TRequest): string;
    abstract getRequestInnerId(request: TRequest): string;
    abstract getResponseId(request: TRequest, response: TServiceResponse): string;

    abstract serviceResponseToError(request: TRequest, response: TServiceResponse): TErrorResponse;

    protected getDebouncer(request: TRequest): IBidRequestDebouncer<TRequest, TResponse, TErrorResponse> | undefined {
        return this.requestDebouncer.get(this.getRequestId(request));
    }
}
