import type { ICreativeComponent } from 'components';
import { IPServices, LocationType } from 'Constants';
import type { IBidRequest } from 'models';
import type { IConfigurationService, ILoggerService, IGeoService } from 'services';
import type { IRequestMiddleware } from 'middleware';

/**
 * Middleware that adds geolocation information to the bid request
 */
export class GeoMiddleware implements IRequestMiddleware {
    private readonly _logger: ILoggerService = globalThis.surfside.logger;
    private readonly _geo: IGeoService = globalThis.surfside.geo;
    /* The configuration service is needed for the geo targeting flag */
    private readonly _config: IConfigurationService = globalThis.surfside.config;

    /**
     * Intercepts the bid request and adds geolocation information to the device object on the bid request
     * @param request The bid request
     * @param component The creative component that is making the request
     * @returns The altered bid request, or the same bid request if geotargeting is not enabled
     */
    async interceptRequest(request: IBidRequest, component: ICreativeComponent): Promise<IBidRequest> {
        this._logger.debug('GeoMiddleware.intercept', request);

        this._logger.debug('GeoMiddleware.intercept getting configuration');
        const geoTargetingEnabled = (await this._config.getConfiguration(component.accountId, component.siteId))
            ?.settings.geoTargetingEnabled ?? true;
        const ipTargetingEnabled = (await this._config.getConfiguration(component.accountId, component.siteId))
            ?.settings.ipTargetingEnabled ?? true;
        this._logger.debug('GeoMiddleware.intercept geoTargetingEnabled', geoTargetingEnabled);

        if (!geoTargetingEnabled && !ipTargetingEnabled) {
            this._logger.debug('GeoMiddleware.intercept geoTargeting not enabled, returning', request);
            return request;
        }

        this._logger.debug('GeoMiddleware.intercept getting geocontext');

        const geoContext = await this._geo.getGeo();
        if (geoContext !== null) {
            this._logger.debug('GeoMiddleware.intercept found geocontext', geoContext);

            if (request.device === undefined) {
                request.device = {};
            }
            if (geoTargetingEnabled) {
                request.device.geo = {
                    lat: geoContext.coords.latitude,
                    lon: geoContext.coords.longitude,
                    city: geoContext.city,
                    region: geoContext.region,
                    country: geoContext.country,
                    zip: geoContext.zip,
                    type: LocationType.IP,
                    accuracy: geoContext.coords.accuracy,
                    utcoffset: geoContext.utcoffset,
                    ipservice: IPServices.MAXMIND
                };
            }
            if (ipTargetingEnabled) {
                request.device.ip = geoContext.ip;
            }
        } else {
            this._logger.warn('GeoMiddleware.intercept did not find geocontext');
        }
        this._logger.debug('GeoMiddleware.intercept returning', request);

        return request;
    }
}
