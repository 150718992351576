import { type IProductInformation } from 'models';

export interface IVast {
    version: string;
    ads: IAd[];
};

export interface IAd {
    id: string | null;
    inline: IInline;
}

export interface IVastProductData extends IProductInformation {
    productId?: string;
}

export interface IInline {
    impression: IImpression;
    adSystem: string;
    adTitle: string;
    creatives: ICreative[];
    product?: IVastProductData;
}

export interface IImpression {
    id: string;
    value: string;
}

/* Marker interface */
export interface ICreative extends Record<string, unknown> { }

/* Empty for now */
export interface ICompanionAds extends ICreative { }

export interface ILinear extends ICreative {
    duration: string;
    trackingEvents: ITrackingEvent[];
    mediaFiles: IMediaFile[];
    videoClicks: IVideoClick[];
}

export interface ITrackingEvent {
    event: string;
    url: string;
}

export interface IMediaFile {
    id: string | null;
    delivery: string;
    type: string;
    width: number;
    height: number;
    bitrate: string | null;
    url: string;
}

export interface IVideoClick {
    id: string | null;
    url: string;
}
