import type {
    IProductInformation
} from 'models';
import { DefaultProductInformation } from 'models/retail/IProductInformation';
import * as helpers from 'utils/handlebars/Helpers';

import type {
    IConfigurationService,
    ILoggerService
} from 'services';

/**
 * Returns the rendered commerce media URL. Retrieves the product from the product service as well as the
 * publisher configuration.
 * @param accountId The account ID for the publisher configuration
 * @param siteId The site ID for the publisher configuration
 * @param locationId The location ID for the product
 * @param product The product from the native bid response; likely to be sparse
 * @param productId The product ID for the product information service, from this specific retailer. Can be null if the
 *                  native creative isn't hooked up to the product catalog
 * @param productInformationService The product information service
 * @param publisherConfigurationService  The publisher configuration service
 * @param logger A logger, for errors if the configuration is missing or the product is missing
 * @returns The rendered commerce media URL, or null if the configuration is missing or the product is missing
 */
export async function RenderCommerceMediaURL(
    accountId: string,
    siteId: string,
    locationId: string,
    product: IProductInformation,
    catalog: IProductInformation | null,
    publisherConfigurationService: IConfigurationService,
    logger: ILoggerService
): Promise<string | null> {
    const publisher = await publisherConfigurationService.getConfiguration(accountId, siteId);
    if (publisher === null) {
        logger.error('RenderCommerceMediaURL no configuration found; returning null');
        return null;
    }

    const template = publisher.templates.clickThroughUrlTemplate;

    if (template === undefined) {
        logger.error('RenderCommerceMediaURL no template found; returning null');
        return null;
    }

    return template({
        product,
        catalog: catalog ?? new DefaultProductInformation(),
        window: {
            origin: window.location.origin,
            port: window.location.port,
            path: window.location.pathname,
            host: window.location.host,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            host_name: window.location.hostname, /* This is a client facing API, so snake_case is acceptable here */
            search: window.location.search,
            hash: window.location.hash,
            protocol: window.location.protocol
        },
        publisher,
        ext: {} /* Not used for now */
    }, {
        helpers
    });
}
