var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "https://"
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"window") : depth0)) != null ? lookupProperty(stack1,"host") : stack1), depth0)) != null ? stack1 : "")
    + "/location/"
    + ((stack1 = (lookupProperty(helpers,"path")||(depth0 && lookupProperty(depth0,"path"))||alias2).call(alias1,1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":43}}})) != null ? stack1 : "")
    + "/"
    + ((stack1 = (lookupProperty(helpers,"lower")||(depth0 && lookupProperty(depth0,"lower"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"productType") : stack1),{"name":"lower","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":73}}})) != null ? stack1 : "")
    + "/"
    + ((stack1 = (lookupProperty(helpers,"slug")||(depth0 && lookupProperty(depth0,"slug"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"slug","hash":{},"data":data,"loc":{"start":{"line":1,"column":74},"end":{"line":1,"column":95}}})) != null ? stack1 : "");
},"useData":true});