import { IDebouncedMulticastRequester } from './IDebouncedMulticastRequester';
import type { IProductInformation, IRecommenderResponse } from 'models';
import type { ILoggerService, IProductInformationService } from 'services';

export enum RecommenderType {
    TOP_ALL = 'top-products',
    FOR_YOU = 'for-you',
    PAST_PURCHASE = 'buy-again'
};

export interface IRecommenderRequest {
    type: RecommenderType;
    placementId: string;
    accountId: string;
    locationId: string;
    siteId: string;
    userId?: string;
};

export abstract class IRecommenderService extends IDebouncedMulticastRequester
    <IRecommenderRequest, IProductInformation, IRecommenderResponse, IRecommenderResponse> {
    private readonly _memoizedRecommenderOffset: Map<string, string>;

    constructor(
        loggerService: ILoggerService,
        private readonly _productInformationService: IProductInformationService

    ) {
        super(loggerService);
        this._memoizedRecommenderOffset = new Map();
    }

    getRequestId(request: IRecommenderRequest): string {
        return request.placementId;
    }

    getRequestInnerId(request: IRecommenderRequest): string {
        const debouncer = this.getDebouncer(request);
        if (debouncer === undefined) {
            throw new Error('No debouncer found for request ' + this.getRequestId(request));
        }
        return String(debouncer.requests.length - 1);
    }

    getResponseId(request: IRecommenderRequest, response: IRecommenderResponse): string {
        return response.id;
    }

    collectRequests(requests: IRecommenderRequest[]): IRecommenderRequest {
        return requests[0];
    }

    serviceResponseToError(request: IRecommenderRequest, response: IRecommenderResponse): IRecommenderResponse {
        return response;
    }

    async collectResponses(
        request: IRecommenderRequest,
        response: IRecommenderResponse
    ): Promise<Array<[string, IProductInformation]>> {
        const debouncer = this.requestDebouncer.get(this.getResponseId(request, response));
        const innerRequests = [...debouncer?.responseHandlers.keys() ?? []];
        const lastPageOffset = this._memoizedRecommenderOffset.get(this._getMemoizedRecommenderID(request));
        let recommendations = response.recommendations;
        if (lastPageOffset !== undefined) {
            const index = recommendations.findIndex(r => r.itemId === lastPageOffset);
            recommendations = recommendations.slice(index + 1);
            recommendations.push(...response.recommendations.slice(0, index)); // put original at the end
        } else {
            this.logger.warn('Failed to find last page offset. Starting from beginning', lastPageOffset);
        }
        const products = (await Promise.all(recommendations
            .map(async (r, index) => {
                try {
                    const product = await this._productInformationService.getProductById(
                        request.accountId,
                        request.siteId,
                        request.locationId,
                        recommendations[index].itemId
                    );
                    return [product, recommendations[index].itemId];
                } catch (error) {
                    this.logger.error('Failed to fetch product information', error);
                    return undefined;
                }
            })
        )).filter(p => p !== undefined) as Array<[IProductInformation, string]>;
        const responses = innerRequests.map((r, index) => [r, products[index][0], products[index][1]]);
        const lastItemId = responses[responses.length - 1][2] as string;
        this._memoizedRecommenderOffset.set(this._getMemoizedRecommenderID(request), lastItemId);
        return responses.map(r => [r[0], r[1]] as [string, IProductInformation]);
    }

    private _getMemoizedRecommenderID(request: IRecommenderRequest): string {
        return `placementId=${request.placementId}&accountId=${request.accountId}` +
            `&siteId=${request.siteId}&locationId=${request.locationId}`;
    }
}
