import { AgentTypes, type IBidRequest } from 'models';
import type {
    ILoggerService,
    ICookieService
} from 'services';
import type { IRequestMiddleware } from 'middleware';

export class IdentityMiddleware implements IRequestMiddleware {
    private readonly _logger: ILoggerService = globalThis.surfside.logger;
    private readonly _cookie: ICookieService = globalThis.surfside.cookie;

    async interceptRequest(
        request: IBidRequest
    ): Promise<IBidRequest> {
        this._logger.debug('IdentityMiddleware.intercept', request);
        const cookie = this._cookie.getCookie();
        this._logger.debug('IdentityMiddleware.intercept cookie', cookie);
        if (cookie !== null) {
            const eid = {
                source: 'surf-domain-id',
                uids: [
                    {
                        id: cookie,
                        atype: AgentTypes.SESSION
                    }
                ]
            };
            if (request.user !== undefined) {
                request.user.eids = [eid];
            } else {
                request.user = {
                    eids: [eid]
                };
            }
        }
        this._logger.debug('IdentityMiddleware.intercept returning', request);
        return request;
    }
}
