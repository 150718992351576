import { SurfColors } from 'Constants';
import { type ILoggerService, LogLevel } from 'services';

type LogFunction = (...args: any[]) => void;

const SURF_PREFIX: string = '%c [SURF] ';

/**
 * Logger service which adds some color to the console logs
 */
export class LocalLoggerService implements ILoggerService {
    constructor(private readonly _logLevel: LogLevel) {
        this.debug('Logging enabled');
        this.debug(`Version: ${process.env.BRANCH_NAME}@${process.env.COMMIT_HASH}`);
    }

    debug(...args: any[]): void {
        if (this._logLevel >= LogLevel.DEBUG) {
            this._log(console.log, args, SurfColors.MAGIC_MINT);
        }
    }

    info(...args: any[]): void {
        if (this._logLevel >= LogLevel.INFO) {
            this._log(console.log, args, SurfColors.LAVENDER);
        }
    }

    warn(...args: any[]): void {
        if (this._logLevel >= LogLevel.INFO) {
            this._log(console.warn, args, SurfColors.SOFT_GOLD);
        }
    }

    error(...args: any[]): void {
        if (this._logLevel >= LogLevel.ERRORS) {
            this._log(console.error, args, SurfColors.ULTRA_RED);
        }
    }

    /**
     * Shows a log message with [SURF] prepended and colored
     * @param log The console log function to use (e.g: console.log, console.error, etc.)
     * @param args The actual log message
     * @param color The color to use for the log message
     */
    private _log(log: LogFunction, args: any[], color: string): void {
        args.unshift(
            `border-radius: 3px; margin-left: 10px; background: ${color}; font-size: 12px; font-weight: bold;`
        );
        args.unshift(SURF_PREFIX);
        log.apply(console, args);
    }
}
