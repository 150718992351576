import { ISimpleCreativeComponent } from 'components';
import { DecodeAdm, RenderAndBind, RenderCommerceMediaURL } from 'utils';
import type { IBidRequest, IBidResponse, ILinear, IProductInformation, IVast } from 'models';
import { DeserializeVast } from 'utils/videos/VastHelpers';
/* Assets */
import template from './SurfVideo.component.hbs';
import watermark from '../watermark/Watermark.component.hbs';
import style from './SurfVideo.component.scss';
import { TryGetDeviceInfo } from 'utils/Device';
import type { IConfigurationService, IProductInformationService } from 'services';

const TIME_FIRST_QUARTER = 0.25;
const TIME_MIDPOINT = 0.5;
const TIME_THIRD_QUARTER = 0.75;

interface VastTemplate {
    vast: IVast;
    watermark: string;
    onToggleMute: (e: Event) => void;
    onLoaded: (e: Event) => void;
    onError: (e: Event) => void;
}

enum TrackerEvent {
    START = 'start',
    FIRST_QUARTILE = 'firstQuartile',
    MIDPOINT = 'midpoint',
    THIRD_QUARTILE = 'thirdQuartile',
    COMPLETE = 'complete',
    STOP = 'stop',
    IMPRESSION = 'impression'
};

interface ITracker {
    fired: boolean;
    url?: string;
};

const MINIMUM_DURATION: number = 0;
const MAXIMUM_DURATION: number = 30;

export class SurfVideo extends ISimpleCreativeComponent {
    private readonly _productInformationService: IProductInformationService =
        globalThis.surfside.productInformationService;

    private readonly _configService: IConfigurationService = globalThis.surfside.config;

    private _template: Handlebars.TemplateDelegate<VastTemplate> | null = null;
    private _video: HTMLVideoElement | null = null;

    private _minDuration: number = MINIMUM_DURATION;
    private _maxDuration: number = MAXIMUM_DURATION;

    private readonly _trackers: Map<TrackerEvent, ITracker> = new Map<TrackerEvent, ITracker>([
        [TrackerEvent.START, { fired: false }],
        [TrackerEvent.FIRST_QUARTILE, { fired: false }],
        [TrackerEvent.MIDPOINT, { fired: false }],
        [TrackerEvent.THIRD_QUARTILE, { fired: false }],
        [TrackerEvent.COMPLETE, { fired: false }],
        [TrackerEvent.STOP, { fired: false }],
        [TrackerEvent.IMPRESSION, { fired: false }]
    ]);

    public async init(): Promise<void> {
        await super.init();
        this._template = template;
        this.logger.debug('SurfVideo.constructor initializing');

        this._minDuration = this.numberOrNull(this.getAttribute('min-duration')) ?? MINIMUM_DURATION;
        try {
            this._maxDuration = this.numberOrError(this.getAttribute('max-duration'));
        } catch (error) {
            throw new Error('SurfVideo.constructor error on attribute "max-duration"; attribute must be a number');
        }
    }

    public async makeRequest(): Promise<IBidRequest> {
        const request: IBidRequest = {
            id: this.placementId,
            imp: [{
                id: this.placementId,
                video: {
                    w: this.width,
                    h: this.height,
                    mimes: ['video/mp4']
                }
            }]
        };
        if (globalThis.debug) {
            this.logger.debug('SurfVideo.makeRequest test mode');
            request.test = 1;
        }
        return request;
    }

    public reauction(): void {
        this.logger.debug('SurfVideo.reauction timed reauctioning not enabled for videos');
    }

    public async render(bidResponse: IBidResponse): Promise<void> {
        const bid = bidResponse.seatbid?.[0].bid?.[0];
        if (bid === undefined) {
            this.fail();
            throw new Error('SurfVideo.render bid is undefined. Bidder returned 200 but no bid.');
        }

        const encodedAdm = bid.adm;
        this.logger.debug('SurfVideo.render adm', encodedAdm);
        if (encodedAdm === undefined) {
            this.fail();
            throw new Error('SurfVideo.render adm is undefined');
        }
        const decodedAdm = DecodeAdm(encodedAdm);
        this.logger.debug('SurfVideo.render decodedAdm', decodedAdm);
        const vast = DeserializeVast(this.logger, decodedAdm);
        this.logger.debug('SurfVideo.render vast', vast);

        if (this._template === null) {
            throw new Error('SurfVideo.render template is null');
        }
        /* Render the template and bind events */
        const rendered = RenderAndBind(this._template, {
            vast,
            watermark: watermark(null),
            onLoaded: this._onLoad.bind(this),
            onError: () => {
                this.logger.error('SurfVideo.render error loading video');
                this.fail();
            },
            onToggleMute: this._onToggleMute.bind(this)
        });

        /* Create stylesheet for component */
        this.logger.debug('SurfVideo.render html', rendered);
        const stylesheet = document.createElement('style');
        stylesheet.innerHTML = style;
        this.logger.debug('SurfVideo.render style', style);
        this.shadow.appendChild(stylesheet);

        this.logger.debug('SurfVideo.render appending children to shadow dom');
        /* Append each rendered child to shadow dom */
        rendered.childNodes.forEach(n => this.shadowRoot?.appendChild(n));
        this._video = this.shadow.querySelector('video');

        const productId = vast.ads[0].inline.product?.productId;
        let catalogProduct: IProductInformation | undefined;
        if (productId !== undefined) {
            catalogProduct = await this._productInformationService.getProductByNativeId(
                this.accountId,
                this.siteId,
                this.locationId,
                productId
            );
        }

        await this._loadTrackers(vast, catalogProduct ?? null);
        this.logger.debug('SurfVideo.render appended children to shadow dom');

        if (bid.nurl !== undefined) {
            const winPixel = document.createElement('img');
            winPixel.src = bid.nurl;
            winPixel.width = 1;
            winPixel.height = 1;
            winPixel.style.display = 'none';
            this.shadow.appendChild(winPixel);
        }
    }

    public fail(): void {
        this.logger.debug('SurfVideo.fail');
        this.remove();
    }

    private async _loadTrackers(vast: IVast, catalog: IProductInformation | null): Promise<void> {
        this.logger.debug('SurfVideo._loadTrackers');
        const creative = vast.ads[0].inline.creatives[0];
        if (creative === undefined) {
            this.logger.error('SurfVideo._loadTrackers creative is undefined');
            return;
        }

        const impression = vast.ads[0].inline.impression;
        if (impression?.value !== undefined) {
            this._trackers.set(TrackerEvent.IMPRESSION, { fired: false, url: impression.value });
        }

        const linear = creative as ILinear;
        const trackers = linear.trackingEvents;
        this.logger.debug('SurfVideo._loadTrackers trackers', trackers);
        for (const tracker of trackers) {
            const eventName = tracker.event;

            /* Janky pattern matching because typescript doesn't have this yet */
            const trackerEvent = (function selectTrackerByName(name: string): TrackerEvent | undefined {
                switch (name) {
                    case 'start':
                        return TrackerEvent.START;
                    case 'firstQuartile':
                        return TrackerEvent.FIRST_QUARTILE;
                    case 'midpoint':
                        return TrackerEvent.MIDPOINT;
                    case 'thirdQuartile':
                        return TrackerEvent.THIRD_QUARTILE;
                    case 'complete':
                        return TrackerEvent.COMPLETE;
                    case 'stop':
                        return TrackerEvent.STOP;
                    default:
                        return undefined;
                }
            })(eventName);

            if (trackerEvent === undefined) {
                this.logger.warn(`SurfVideo._loadTrackers unknown event ${eventName}`);
                continue;
            }
            this._trackers.set(trackerEvent, { fired: false, url: tracker.url });
        }

        if (linear.videoClicks.length > 0) {
            let clickthroughUrl: string | null = linear.videoClicks[0].url;
            if (clickthroughUrl.includes('{SURF_COMMERCE_URL}')) {
                const product = vast.ads[0].inline.product;
                if (product === undefined) {
                    this.logger.error('SurfVideo._loadTrackers product is undefined; cannot render commerce URL');
                    return;
                }
                const renderedClickthroughUrl = await RenderCommerceMediaURL(
                    this.accountId,
                    this.siteId,
                    this.locationId,
                    product,
                    catalog ?? null,
                    this._configService,
                    this.logger
                );

                if (renderedClickthroughUrl === null) {
                    this.logger.error(
                        'SurfVideo._loadTrackers renderedClickthroughUrl is null; cannot render clickthrough URL'
                    );
                    return;
                }

                clickthroughUrl = clickthroughUrl.replace('{SURF_COMMERCE_URL}', renderedClickthroughUrl);
            }

            if (clickthroughUrl === null) {
                this.logger.warn('SurfVideo._loadTrackers clickthroughUrl is null; cannot render clickthrough URL!');
                return;
            }
            this._video?.addEventListener('click', function clickThroughVideo(this: SurfVideo) {
                this.logger.debug('SurfVideo._loadTrackers video clickthrough', clickthroughUrl);
                window.open(clickthroughUrl, '_blank');
            }.bind(this));
        }
        this.logger.debug('SurfVideo._loadTrackers trackers', this._trackers);
    }

    private async _onLoad(): Promise<void> {
        if (this._video === null) {
            this.logger.error('Cannot start video; video is null');
            this.fail();
            return;
        }

        const circle = this.shadow.querySelector('.timer-circle');
        if (circle !== null) {
            this.logger.debug('SurfVideo.render circle', circle);
            (circle as SVGAElement).style.animationDuration = `${this._video?.duration ?? 0}s`;
            (circle as SVGAElement).style.animationPlayState = 'running';
        }

        const timer = this.shadow.querySelector('.timer-value');
        if (timer !== null) {
            this.logger.debug('SurfVideo.render timer', timer);
            const duration = this._video?.duration ?? 0;
            timer.innerHTML = String(duration);
        }

        this._video.onplay = async function videoPlay(this: SurfVideo) {
            this.logger.debug('SurfVideo._onLoad video play');
            await this._fireTracker(TrackerEvent.START);
        }.bind(this);

        this._video.onended = async function videoEnd(this: SurfVideo) {
            this.logger.debug('SurfVideo._onLoad video end');
            await this._fireTracker(TrackerEvent.COMPLETE);

            const configuration = await this.configuration;
            if (configuration?.settings.reauctionEnabled === true) {
                this.logger.debug('SurfVideo._onLoad reauctioning after video end');
                const replica = this.outerHTML;
                this.logger.debug('SurfVideo._onLoad reauctioning replica', replica);
                this.outerHTML = replica;
            }
        }.bind(this);

        this._video.ontimeupdate = async function videoTimeUpdate(this: SurfVideo) {
            if (this._video === null) {
                this.logger.error('Cannot update video time; video is null');
                return;
            }
            const timeNode = this.shadow.querySelector('.timer-value');
            const currentTime = Math.floor(this._video.currentTime);

            if (timeNode !== null) {
                timeNode.innerHTML = String(currentTime);
            }

            const percent = currentTime / this._video.duration;
            if (percent >= TIME_FIRST_QUARTER && percent < TIME_MIDPOINT) {
                await this._fireTracker(TrackerEvent.FIRST_QUARTILE);
            } else if (percent >= TIME_MIDPOINT && percent < TIME_THIRD_QUARTER) {
                await this._fireTracker(TrackerEvent.MIDPOINT);
            } else if (percent >= TIME_THIRD_QUARTER) {
                await this._fireTracker(TrackerEvent.THIRD_QUARTILE);
            }
        }.bind(this);

        await this._fireTracker(TrackerEvent.IMPRESSION);
    }

    private async _fireTracker(tracker: TrackerEvent): Promise<void> {
        const trackerData = this._trackers.get(tracker);
        if (trackerData === undefined) {
            this.logger.error('Cannot fire tracker; tracker data is undefined', tracker);
            return;
        }

        if (trackerData.fired) {
            /* Not printing a debug here because it would spam the logs */
            return;
        }

        const urlString = trackerData.url;
        if (urlString === undefined) {
            this.logger.error('Cannot fire tracker; url is undefined', tracker);
            return;
        }
        const url = new URL(urlString);

        this.logger.debug(`SurfVideo._fireTracker firing tracker [${tracker}]`, url);
        const device = TryGetDeviceInfo();
        if (device !== null) {
            for (const [key, value] of Object.entries(device)) {
                this.logger.debug('SurfVideo._fireTracker adding device info', key, value);
                if (value !== undefined) {
                    url.searchParams.set(key, value as string);
                } else {
                    this.logger.warn('SurfVideo._fireTracker device info is undefined', key);
                }
            }
        }
        url.searchParams.set('ua', navigator.userAgent);
        const coords = (await globalThis.surfside.geo.getGeo())?.coords;
        url.searchParams.set('lat', coords?.latitude.toString() ?? '');
        url.searchParams.set('lon', coords?.longitude.toString() ?? '');

        trackerData.fired = true;
        fetch(url, {
            method: 'GET',
            mode: 'no-cors'
        }).then(() => {
            this.logger.debug(`SurfVideo._fireTracker fired tracker [${tracker}]`);
        }).catch((error) => {
            this.logger.error(`SurfVideo._fireTracker error firing tracker [${tracker}]`, error);
        });
    }

    private _onToggleMute(e: Event): void {
        this.logger.debug('_onToggleMute');
        if (this._video === null || e.target === null) {
            this.logger.error('Cannot toggle mute button; video or target is null');
            return;
        }
        const element = e.target as HTMLElement;
        this.logger.debug('_onToggleMute element', element);

        this._video.muted = !this._video.muted;
        this.logger.debug('_onToggleMute video.muted', this._video.muted);
        if (this._video.muted) {
            element.classList.add('muted');
            element.classList.remove('unmuted');
        } else {
            element.classList.add('unmuted');
            element.classList.remove('muted');
        }
        this.logger.debug('_onToggleMute element.classList', element.classList);
    }
}
