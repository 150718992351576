var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"creatives") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":21}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"mediaFiles") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":10,"column":25}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <source src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":9,"column":33},"end":{"line":9,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\" type=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":56}}}) : helper))) != null ? stack1 : "")
    + "\" id=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":62},"end":{"line":9,"column":68}}}) : helper))) != null ? stack1 : "")
    + "\" _error_=\"onError\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"surfside-video interaction-style-wrapper\">\n    <div class=\"sizing-container\">\n        <video muted autoplay _loadeddata_=\"onLoaded\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"vast") : depth0)) != null ? lookupProperty(stack1,"ads") : stack1)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"inline") : stack1),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + "        </video>\n\n        <div class=\"timer\">\n            <svg\n                width=\"36\"\n                height=\"36\"\n                viewBox=\"-2 2 36 36\"\n            >\n                <circle\n                    cx=\"16\"\n                    cy=\"16\"\n                    r=\"15\"\n                    stroke=\"#7b4af4\"\n                    stroke-width=\"4\"\n                    fill=\"none\"\n                    class=\"timer-circle\"\n                />\n            </svg>\n            <div class=\"timer-value\"></div>\n        </div>\n\n        <div class=\"surfside-watermark\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"watermark") || (depth0 != null ? lookupProperty(depth0,"watermark") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"watermark","hash":{},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":27}}}) : helper))) != null ? stack1 : "")
    + " \n        </div>\n\n        <div class=\"mute-button muted\" _click_=\"onToggleMute\"></div>\n\n    </div>\n</div>";
},"useData":true});