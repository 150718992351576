import type {
    ICallbacks,
    ICompiledPublisherConfiguration,
    ICompiledPublisherConfigurationDTO,
    IProductContext
} from 'models';
import type { ILoggerService } from 'services';

/**
 * The configuration service interface. account and site IDs come from the creative component attributes.
 * Publisher configurations have flags for features on the site, and templates for rendering commerce media creatives.
 */
export abstract class IConfigurationService {
    protected readonly logger: ILoggerService;
    constructor(logger: ILoggerService) {
        this.logger = logger;
    }

    /**
     * Gets the configuration for the given account and site IDs
     * @param accountId The account ID of the component making the request
     * @param siteId The site ID of the component making the request
     * @returns The configuration for the account and site IDs, or null if no configuration is found
     */
    abstract getConfiguration(accountId: string, siteId: string): Promise<ICompiledPublisherConfiguration | null>;

    private async _mapPrecompileTemplate(template: string): Promise<HandlebarsTemplateDelegate<IProductContext>> {
        if (template === '' || template === undefined) {
            this.logger.debug('MockConfigurationService._mapPrecompileTemplate empty template');
            return () => '';
        }
        this.logger.debug('MockConfigurationService._mapPrecompileTemplate precompiling:', template);
        return await new Promise((
            resolve: (value: HandlebarsTemplateDelegate<IProductContext>) => void,
            reject: any
        ) => {
            const divReceiver = document.createElement('div');
            const base = 36;
            const maxLength = 7;
            const randomId = Math.random().toString(base).substring(maxLength);
            divReceiver.setAttribute('id', 'receiver_' + randomId);
            divReceiver.addEventListener('templateEvaluated', (e: Event) => {
                this.logger.debug('MockConfigurationService._mapPrecompileTemplate', e);
                const customEvent = e as CustomEvent<TemplateSpecification>;
                const templateDelegate = globalThis.handlebars.template<IProductContext>(customEvent.detail);
                resolve(templateDelegate);
                this.logger.debug('MockConfigurationService._mapPrecompileTemplate removing elements');
                divReceiver.remove();
                script.remove();
            });
            const maxTimeout = 5000;
            setTimeout(() => {
                reject(new Error('Template evaluation timed out'));
            }, maxTimeout);
            document.body.appendChild(divReceiver);
            const script = document.createElement('script');
            script.textContent = `(function() {
                const raw = ${template};
                document.getElementById('receiver_${randomId}')
                    .dispatchEvent(new CustomEvent('templateEvaluated', {
                        detail: raw
                    }));
            })()`;
            document.head.appendChild(script);
        });
    }

    protected async mapConfig(config: ICompiledPublisherConfigurationDTO): Promise<ICompiledPublisherConfiguration> {
        this.logger.debug('MockConfigurationService.mapConfig mapping:', config);
        this.logger.debug('MockConfigurationService.mapConfig mapping templates:', config.templates);
        const productCardTemplate = config.templates.productCardTemplate;
        this.logger.debug('MockConfigurationService.mapConfig mapping template productcardtemplate:',
            productCardTemplate
        );
        return {
            settings: config.settings,
            templates: {
                clickThroughUrlTemplate: await this._mapPrecompileTemplate(
                    config.templates.clickThroughUrlTemplate ?? ''
                ),
                productCardTemplate: await this._mapPrecompileTemplate(
                    productCardTemplate ?? ''
                ),
                productCardStylesheet: config.templates.productCardStylesheet,
                carouselStylesheet: config.templates.carouselStylesheet,
                javascriptCallbacks: await this.exportJavascriptCallbackModule(config.templates.javascriptCallbacks)
            }
        };
    }

    protected async exportJavascriptCallbackModule(callbacks: string): Promise<ICallbacks> {
        this.logger.debug('MockConfigurationService.exportJavascriptCallbackModule exporting:', callbacks);
        const blob = new Blob([callbacks], { type: 'text/javascript' });
        const moduleUrl = URL.createObjectURL(blob);
        this.logger.debug('ModuleUrl', moduleUrl);

        const module = await import(/* webpackIgnore: true */ moduleUrl) as ICallbacks;
        this.logger.debug('MockConfigurationService.exportJavascriptCallbackModule module:', module);
        this.logger.debug('MockConfigurationService.exportJavascriptCallbackModule module keys:', Object.keys(module));
        return module;
    }
}
