import {
    SurfBanner,
    SurfBreakpoint,
    SurfCarousel,
    SurfProductCard,
    SurfVideo
} from 'components';
import { Environment } from 'Constants';
import {
    InteractiveMessageEvents,
    type IInteractiveMessage,
    type ISetConfigMessage
} from 'models/messages/InteractiveMessages';
import {
    type IBidderService,
    type IConfigurationService,
    type ICookieService,
    type IGeoService,
    type ILoggerService,
    type IProductInformationService,
    type IRecommenderService,
    type ISnowplowInit,

    LocalLoggerService,
    LogLevel,

    S3ConfigurationService,
    S3ProductInformationService,
    SnowplowInitService,
    SurfsideBidderService,
    SurfsideCookieService,
    SurfsideGeoService,

    MockBidderService,
    MockConfigurationService,
    MockCookieService,
    MockGeoService,
    MockProductInformationService,
    MockRecommenderService
} from 'services';
import { SurfsideRecommenderService } from 'services/SurfsideRecommenderService';

import { GetParameter, initHandlebars } from 'utils';

import Handlebars from 'handlebars/runtime';

export default function initSurfsideAds(): void {
    initApi();
    initHandlebars(globalThis.surfside.logger);
    initWebComponents();
}

function initApi(): void {
    /* These are replaced inline by Webpack's DefinePlugin, and are hard to use in other contexts */
    const ENV_LOG_LEVEL: string = process.env.LOG_LEVEL ?? LogLevel[LogLevel.NONE].toString();
    const ENV_ENVIRONMENT: string = process.env.ENVIRONMENT ?? Environment.LOCAL.toString();

    globalThis.debug = GetParameter('surf_debug') === 'true' ||
        (process.env.ENVIRONMENT !== Environment.PROD.toString());
    const environment: Environment = (Environment as any)[ENV_ENVIRONMENT] as (Environment | undefined) ??
        Environment.LOCAL;

    globalThis.handlebars = Handlebars.create();
    /* Production builds should set LOG_LEVEL to NONE */
    const logLevel: LogLevel = globalThis.debug
        ? LogLevel.DEBUG /* If debug enabled, always show debug logs */
        : (
            (LogLevel as any)[ENV_LOG_LEVEL] ?? /* If log level is set, use that */
            LogLevel.INFO /* Otherwise, default to INFO */
        );

    let logger: ILoggerService;
    let bidder: IBidderService;
    let config: IConfigurationService;
    let geo: IGeoService;
    let productInformationService: IProductInformationService;
    let recommenderService: IRecommenderService;
    let cookie: ICookieService;

    switch (environment) {
        case Environment.PROD: {
            logger = new LocalLoggerService(logLevel);
            bidder = new SurfsideBidderService(environment, logger);
            config = new S3ConfigurationService(environment, logger);
            geo = new SurfsideGeoService(logger);
            productInformationService = new S3ProductInformationService(environment, logger);
            cookie = new SurfsideCookieService(logger);
            recommenderService = new SurfsideRecommenderService(
                logger,
                productInformationService,
                cookie,
                environment
            );
            break;
        }
        case Environment.LOCAL: {
            logger = new LocalLoggerService(logLevel);
            bidder = new SurfsideBidderService(environment, logger);
            config = new S3ConfigurationService(environment, logger);
            geo = new SurfsideGeoService(logger);
            productInformationService = new S3ProductInformationService(environment, logger);
            cookie = new SurfsideCookieService(logger);
            recommenderService = new SurfsideRecommenderService(
                logger,
                productInformationService,
                cookie,
                environment
            );
            break;
        }
        case Environment.MOCK: {
            logger = new LocalLoggerService(logLevel);
            bidder = new MockBidderService(logger);
            config = new MockConfigurationService(logger);
            geo = new MockGeoService(logger);
            productInformationService = new MockProductInformationService(logger);
            recommenderService = new MockRecommenderService(logger, productInformationService);
            cookie = new MockCookieService(logger);
            break;
        }
        case Environment.STAGING:
        default: {
            logger = new LocalLoggerService(logLevel);
            bidder = new SurfsideBidderService(environment, logger);
            config = new S3ConfigurationService(environment, logger);
            geo = new SurfsideGeoService(logger);
            productInformationService = new MockProductInformationService(logger);
            cookie = new SurfsideCookieService(logger);
            recommenderService = new SurfsideRecommenderService(
                logger,
                productInformationService,
                cookie,
                environment
            );
        }
    }

    const snowplowInit: ISnowplowInit = new SnowplowInitService(logger);
    snowplowInit.initSnowplow();

    if (environment === Environment.MOCK) {
        listenInteractiveMode(logger);
    }
    globalThis.surfside = {
        logger,
        bidder,
        config,
        geo,
        productInformationService,
        recommenderService,
        cookie
    };

    logger.info('Initialized Surfside Ads 🏄‍♂️');
}

function initWebComponents(): void {
    const logger = globalThis.surfside.logger;

    logger.debug('Initializing SurfBanner web components');

    customElements.define('surf-banner', SurfBanner);
    customElements.define('surf-product-card', SurfProductCard);
    customElements.define('surf-video', SurfVideo);
    customElements.define('surf-carousel', SurfCarousel);
    customElements.define('surf-breakpoint', SurfBreakpoint);

    logger.debug('SurfBanner web components initialized');
}

if (process.env.SURF_SCRIPT_BUILD === 'true') {
    if (document.readyState === 'complete') {
        initSurfsideAds();
    } else {
        window.addEventListener('load', initSurfsideAds);
    };
}

function listenInteractiveMode(logger: ILoggerService): void {
    window.addEventListener('message', (event: MessageEvent<IInteractiveMessage>) => {
        const message = event.data;
        logger.debug('Received message', message);

        if (message.namespace !== 'surfside') {
            logger.debug('Message namespace is not surfside, ignoring');
            return;
        }
        logger.debug('Message event is', message.event);
        // ====== Handle set config ====== //
        switch (message.event) {
            case InteractiveMessageEvents.SET_CONFIG: {
                logger.debug('Setting config', message.data);
                const configMessage = message as ISetConfigMessage;
                (globalThis.surfside.config as MockConfigurationService)
                    .setConfiguration(configMessage.data)
                    .then(() => {
                        logger.debug('Config set');
                    })
                    .catch((error) => {
                        logger.error('Error setting config', error);
                    });
                break;
            }
        }
    });

    window.postMessage({
        namespace: 'surfside',
        event: InteractiveMessageEvents.READY
    }, '*');
}
