/**
 * Get the value of a query string parameter by name.
 *
 * @param name The name of the query parameter to get
 * @returns The value of the query parameter, or undefined if the parameter is not found
 */
export function GetParameter(name: string): string | undefined {
    return window.location.search
        .replace('?', '')
        .split('&')
        .map(kvp => kvp.split('='))
        .filter(kvp => kvp[0] === name)
        .map(kvp => kvp.length > 1 ? kvp[1] : 'true')
        ?.[0];
}
