import { Environment } from 'Constants';
import type { IProductInformation } from 'models';
import type { IProductInformationService, ILoggerService } from 'services';

export class S3ProductInformationService implements IProductInformationService {
    private readonly _host: string;

    private readonly _memoizedProductInformation = new Map<string, IProductInformation>();

    constructor(private readonly _env: Environment, private readonly _logger: ILoggerService) {
        this._logger.debug('S3ProductInformationService.constructor initializing');
        switch (this._env) {
            case Environment.PROD: {
                this._host = 'https://surf-creative-assets.s3.amazonaws.com';
                break;
            }
            case Environment.STAGING: {
                this._host = 'https://surf-creative-assets-test.s3.us-east-1.amazonaws.com';
                break;
            }
            case Environment.LOCAL: {
                this._host = 'http://localhost:9001/local-product-information-service';
                break;
            }
            default: {
                this._logger.error('S3ProductInformationService.constructor invalid environment');
                this._host = 'about:blank';
                break;
            }
        }
    }

    async getProductById(
        accountId: string,
        siteId: string,
        locationId: string,
        productId: string
    ): Promise<IProductInformation> {
        const key = `${accountId}:${siteId}:${locationId}:${productId}`;
        if (this._memoizedProductInformation.has(key)) {
            const value = this._memoizedProductInformation.get(key);
            if (value !== undefined) {
                return value;
            }
        }
        this._logger.debug('S3ProductInformationService.getProductById', productId, locationId);

        const url = `${this._host}/product/${accountId}/${siteId}/${locationId}/${productId}.json`;
        this._logger.debug('S3ProductInformationService.getProductById fetching', url);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`S3ProductInformationService.getProductById failed to fetch ${url}`);
        }

        const info = this._mapResponseToProductInformation(await response.json() as Record<string, any>);
        this._memoizedProductInformation.set(key, info);
        return info;
    }

    async getProductByNativeId(
        accountId: string,
        siteId: string,
        locationId: string,
        productId: string
    ): Promise<IProductInformation> {
        this._logger.debug('S3ProductInformationService.getProductByNativeId', productId, locationId);
        const key = `${accountId}:${siteId}:${locationId}:${productId}`;
        if (this._memoizedProductInformation.has(key)) {
            const value = this._memoizedProductInformation.get(key);
            if (value !== undefined) {
                return value;
            }
        }

        const url = `${this._host}/native/${accountId}/${siteId}/${locationId}/${productId}.json`;
        this._logger.debug('S3ProductInformationService.getProductByNativeId fetching', url);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`S3ProductInformationService.getProductByNativeId failed to fetch ${url}`);
        }

        const info = this._mapResponseToProductInformation(await response.json() as Record<string, any>);
        this._memoizedProductInformation.set(key, info);
        return info;
    }

    private _mapResponseToProductInformation(data: Record<string, any>): IProductInformation {
        return {
            image: this._getFieldOrNull(data, 'image_link'),
            price: this._getFieldOrNull(data, 'price'),
            salePrice: this._getFieldOrNull(data, 'sale_price'),
            thc: this._getFieldOrNull(data, 'thc'),
            cbd: this._getFieldOrNull(data, 'cbd'),
            strain: this._getFieldOrNull(data, 'strain'),
            name: this._getFieldOrNull(data, 'name'),
            brandName: this._getFieldOrNull(data, 'brand'),
            productType: this._getFieldOrNull(data, 'productType'),
            details: this._getFieldOrNull(data, 'details'),
            cta: this._getFieldOrNull(data, 'cta'),
            clickthroughUrl: this._getFieldOrNull(data, 'clickthroughUrl'),
            winUrl: this._getFieldOrNull(data, 'winUrl'),
            pixelUrl: this._getFieldOrNull(data, 'pixelUrl'),
            ext: data
        };
    }

    private _getFieldOrNull(data: Record<string, any>, field: string): string | null {
        if (field in data) {
            return String(data[field]);
        }
        return null;
    }
}
