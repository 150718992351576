import type { IRecommenderResponse } from 'models/retail/IRecommenderResponse';
import { type IRecommenderRequest, IRecommenderService } from 'services/IRecommenderService';

export class MockRecommenderService extends IRecommenderService {
    async sendRequestToService(request: IRecommenderRequest): Promise<IRecommenderResponse> {
        return {
            id: request.placementId,
            locationId: request.locationId,
            recommendations: [
                { itemId: '12345' },
                { itemId: '12346' },
                { itemId: '12347' },
                { itemId: '12348' },
                { itemId: '12349' },
                { itemId: '123410' },
                { itemId: '123411' },
                { itemId: '123412' },
                { itemId: '123413' },
                { itemId: '123414' },
                { itemId: '123415' },
                { itemId: '123416' },
                { itemId: '123417' },
                { itemId: '123418' },
                { itemId: '123419' }
            ]
        };
    }
}
