import type { IRecommenderResponse } from 'models';
import {
    type ICookieService,
    type ILoggerService,
    type IProductInformationService,
    IRecommenderService,
    RecommenderType
} from 'services';
import { Environment } from 'Constants';
import type { IRecommenderRequest } from './IRecommenderService';

export class SurfsideRecommenderService extends IRecommenderService {
    private readonly _url: URL;

    private readonly _topProductRecommendations = new Map<string, IRecommenderResponse>();
    private readonly _cookieService;

    constructor(
        logger: ILoggerService,
        productInformationService: IProductInformationService,
        cookieService: ICookieService,
        env: Environment
    ) {
        super(logger, productInformationService);
        this._cookieService = cookieService;
        switch (env) {
            case Environment.PROD:
                this._url = new URL(window.location.protocol + '//t.surfside.io');
                break;
            case Environment.STAGING:
                this._url = new URL(window.location.protocol + '//t.surfside.io');
                break;
            case Environment.LOCAL:
                this._url = new URL('http://localhost:9001/local-recommender-service');
                break;
            default:
                throw new Error('Invalid environment for SurfsideRecommenderService');
        }
    }

    async sendRequestToService(request: IRecommenderRequest): Promise<IRecommenderResponse> {
        const params = new URLSearchParams();

        params.append('accountId', request.accountId);
        params.append('siteId', request.siteId);
        params.append('locationId', request.locationId);
        if (request.type !== RecommenderType.TOP_ALL) {
            const userId = this._cookieService.getCookie();
            if (userId === null) { /* No user ID available, default to top-all */
                request.type = RecommenderType.TOP_ALL;
            } else { /* If a user ID is present, append the user ID */
                params.append('userId', userId);
            }
        }
        const key = params.toString() + request.type;
        if (this._topProductRecommendations.has(key)) {
            const value = this._topProductRecommendations.get(key);
            if (value !== undefined) {
                return value;
            }
        }
        const result = await fetch(`${this._url.toString()}r/${request.type}/?${params.toString()}`);
        if (result.ok) {
            const recommendations = await result.json();
            const response = {
                id: this.getRequestId(request),
                locationId: request.locationId,
                recommendations: recommendations.map((r: any) => ({ itemId: r.item_id }))
            };
            this._topProductRecommendations.set(key, response);
            return response;
        } else {
            throw new Error('Failed to fetch recommender response. Got: ' + result.statusText);
        }
    }
}
