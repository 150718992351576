export enum LogLevel {
    /* Do not show any logs. Used in production */
    NONE,
    /* Errors only */
    ERRORS,
    /* Errors and Info only */
    INFO,
    /* Display all logs */
    DEBUG
}

/**
 * Interface for a logger service
 */
export interface ILoggerService {
    /**
     * Prints info. This is useful for tracking the lifecycle of a bid request
     *
     * @param args The arguments to print
     */
    info: (...args: any[]) => void;

    /**
     * Prints a warning. This is useful for tracking potential issues, but not critical errors. Warnings should not
     * prevent the bidding process from continuing, but may disable certain features such as commerce media or certain
     * types of targeting
     *
     * @param args
     */
    warn: (...args: any[]) => void;

    /**
     * Prints an error. This is useful for tracking critical errors. Printing an error should indicate that the bidding
     * process can go no further.
     *
     * @param args The arguments to print
     */
    error: (...args: any[]) => void;

    /**
     * Spammy messages. This is useful for tracking the lifecycle of a bid request and debugging issues with special
     * flags enabled. When there is an issue on the client side, enabling debug logs will help the developer understand
     * where the issue is in the code and potentially what the issue is. These should be added to every function.
     * @param args The arguments to print
     */
    debug: (...args: any[]) => void;
}
