import { type IBidRequest } from 'models';
import type {
    ILoggerService
} from 'services';
import type { IRequestMiddleware } from 'middleware';
import type { ISnowplowBidRequestedEvent } from 'models/rtb/ISnowplowTypes';

export class SnowplowRequestMiddleware implements IRequestMiddleware {
    private readonly _logger: ILoggerService = globalThis.surfside.logger;

    async interceptRequest(
        request: IBidRequest
    ): Promise<IBidRequest> {
        this._logger.debug('SnowplowRequestMiddleware.intercept', request);
        const snowplowBidRequestPayload: ISnowplowBidRequestedEvent = {
            auctionId: request.id,
            bidderRequestId: '',
            bids: request.imp?.map(imp => {
                return {
                    params: {
                        accountId: request.ext?.accountId ?? null,
                        channelId: request.ext?.channelId ?? null,
                        siteId: request.ext?.siteId ?? null,
                        placementId: request.ext?.placementId ?? null,
                        zone: request.ext?.zone ?? null,
                        type: request.ext?.type ?? null,
                        storeId: request.ext?.storeId ?? null
                    },
                    user: request?.user?.geo !== undefined ? { geo: request.user.geo } : undefined,
                    ortb2Imp: {
                        ext: {
                            tid: request?.source?.tid !== undefined ? request.source.tid : ''
                        }
                    },
                    mediaTypes: {
                        banner: imp?.banner?.w !== undefined && imp?.banner?.h !== undefined
                            ? {
                                sizes: [imp.banner.w, imp.banner.h]
                            }
                            : undefined
                    },
                    transactionId: request?.source?.tid ?? '',
                    sizes: imp?.banner?.w !== undefined && imp?.banner?.h !== undefined
                        ? [imp.banner.w, imp.banner.h]
                        : [0, 0],
                    bidId: imp.id,
                    ortb2: {
                        site: {
                            domain: window.location.hostname,
                            page: window.location.pathname
                        },
                        device: {
                            w: window.screen.width,
                            h: window.screen.height,
                            dnt: 0,
                            ua: window.navigator.userAgent,
                            language: window.navigator.language
                        }
                    }
                };
            }),
            auctionStart: Date.now(),
            timeout: 0,
            refererInfo: {
                stack: [document.referrer],
                location: window.location.href,
                page: window.location.href,
                domain: window.location.hostname
            },
            start: Date.now()
        };
        if (window.surf !== undefined) {
            this._logger.debug('Sending bidRequested event to Snowplow', snowplowBidRequestPayload);
            window.surf('bidRequested:ads', snowplowBidRequestPayload);
        }
        this._logger.debug('SnowplowRequestMiddleware.intercept returning', request);
        return request;
    }
}
