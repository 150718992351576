export interface IProductInformation {
    image: string | null;
    price: string | null;
    salePrice: string | null;
    thc: string | null;
    strain: string | null;
    cbd: string | null;
    name: string | null;
    brandName: string | null;
    productType: string | null;
    details: string | null;
    cta: string | null;
    clickthroughUrl: string | null;
    winUrl: string | null;
    pixelUrl: string | null;
    ext?: any;
}

export class DefaultProductInformation implements IProductInformation {
    image: string | null;
    price: string | null;
    salePrice: string | null;
    thc: string | null;
    strain: string | null;
    cbd: string | null;
    name: string | null;
    brandName: string | null;
    productType: string | null;
    details: string | null;
    cta: string | null;
    clickthroughUrl: string | null;
    winUrl: string | null;
    pixelUrl: string | null;
    ext?: any;

    constructor() {
        this.image = null;
        this.price = null;
        this.salePrice = null;
        this.thc = null;
        this.strain = null;
        this.cbd = null;
        this.name = null;
        this.brandName = null;
        this.productType = null;
        this.details = null;
        this.cta = null;
        this.clickthroughUrl = null;
        this.winUrl = null;
        this.pixelUrl = null;
    }
}
