import type { IBid, IBidRequest, IBidResponse, IImp } from 'models';
import { IDebouncedMulticastRequester } from './IDebouncedMulticastRequester';

type DelegatedBidResponse = [IBidResponse, IBid]; // tuple
/**
 * The bidder service interface. Simply sends a bid and returns a bid response
 */
export abstract class IBidderService extends IDebouncedMulticastRequester
    <IBidRequest, DelegatedBidResponse, IBidResponse, IBidResponse> {
    /**
     * Sends a bid request to the bidder endpoint and returns the bid response. Throws an error if no bid response
     * is returned.
     * @param request The bid request
     * @returns The bid response
     */
    public async bid(request: IBidRequest): Promise<DelegatedBidResponse> {
        return await this.request(request);
    }

    getRequestId(request: IBidRequest): string {
        return request.id;
    }

    getRequestInnerId(request: IBidRequest): string {
        const debouncer = this.getDebouncer(request);
        if (debouncer === undefined) {
            throw new Error(
                'Could not find debouncer associated with request when finding bid request inner ID ' + request.id
            );
        }

        return String(debouncer.requests.length - 1);
    }

    getResponseId(request: IBidRequest, response: IBidResponse): string {
        return response.id;
    }

    collectRequests(bidRequests: IBidRequest[]): IBidRequest {
        const imps: IImp[] = [];
        let id = 0;
        for (const req of bidRequests) {
            for (let i = 0; i < req.imp.length; i++) {
                req.imp[i].id = String(id++);
                imps.push(req.imp[i]);
            }
        }

        return {
            ...bidRequests[0], /* Use the first bid request as the basis. It will have all the same metadata */
            imp: imps
        };
    }

    serviceResponseToError(request: IBidRequest, response: IBidResponse): IBidResponse {
        return response;
    }

    async collectResponses(
        request: IBidRequest,
        response: IBidResponse
    ): Promise<Array<[string, DelegatedBidResponse]>> {
        const responses = new Array<[string, DelegatedBidResponse]>();
        const seatbid = response.seatbid?.[0].bid ?? [];
        for (const bid of seatbid) {
            responses.push([bid.impid, [response, bid]]);
        }
        return responses;
    }
}
