import type {
    ContextSubTypes,
    ContextTypes,
    EventTrackingMethods,
    EventTypes,
    NativeDataTypes,
    PlacementTypes,
    VideoMimes
} from './NativeConstants';

export interface INativeRequest {
    ver?: string;
    context?: ContextTypes;
    contextsubtype?: ContextSubTypes;
    plcmttype?: PlacementTypes;
    plcmtcnt?: number;
    seq?: number;
    assets: IAssetObject[];
    aurlsupport?: number;
    durlsupport?: number;
    eventtrackers?: IEventTrackerRequestObject[];
    privacy?: number;
    ext?: any;
}

export interface IAssetObject {
    id: number;
    required?: number;
    title?: ITitleRequestObject;
    img?: IImageRequestObject;
    video?: IVideoRequestObject;
    data?: IDataRequestObject;
    ext?: any;
}

export interface ITitleRequestObject {
    len: number;
    ext?: any;
}

export interface IImageRequestObject {
    type?: number;
    w?: number;
    wmin?: number;
    h?: number;
    hmin?: number;
    mimes?: string[];
    ext?: any;
}

export interface IVideoRequestObject {
    mimes: VideoMimes[] | string[];
    minduration: number;
    maxduration: number;
    protocols: number[];
    ext?: any;
}

export interface IDataRequestObject {
    type: NativeDataTypes;
    len?: number;
    ext?: any;
}

export interface IEventTrackerRequestObject {
    event: EventTypes;
    methods: EventTrackingMethods[];
    ext?: any;
}
