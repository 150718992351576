export interface ICoordinates {
    latitude: number;
    longitude: number;
    accuracy: number;
}

export interface IGeoContext {
    country: string;
    region: string;
    city: string;
    zip: string;
    coords: ICoordinates;
    utcoffset: number;
    ip: string;
}
