export enum NativePosition {
    UNKNOWN = 0,
    ABOVE_THE_FOLD = 1,
    BELOW_THE_FOLD = 3,
    HEADER = 4,
    FOOTER = 5,
    SIDEBAR = 6,
    FULL_SCREN = 7
};

export enum NativeImageTypes {
    ICON = 1,
    MAIN = 3
}

export enum NativeDataTypes {
    SPONSORED = 1,
    DESC = 2,
    RATING = 3,
    LIKES = 4,
    DOWNLOADS = 5,
    PRICE = 6,
    SALEPRICE = 7,
    PHONE = 8,
    ADDRESS = 9,
    DESC2 = 10,
    DISPLAYURL = 11,
    CTATEXT = 12,

    /* Surfside Specific codes */
    SURF_STRAIN = 500,
    SURF_THC = 501,
    SURF_CBD = 502,
    SURF_CATEGORY = 503,
    SURF_META_PRODUCT = 599
}

/* Used for addressing the ID of the asset field */
export enum NativeAssetId {
    TITLE = 0,
    IMAGE = 1,
    SPONSORED = 2,
    DESC = 3,
    RATING = 4,
    PRICE = 5,
    SALEPRICE = 6,
    DISPLAYURL = 7,
    CTATEXT = 8,

    SURF_STRAIN = 500,
    SURF_THC = 501,
    SURF_CBD = 502,
    SURF_CATEGORY = 503,
    SURF_META_PRODUCT = 599
}

export enum EventTypes {
    IMP = 1,
    VIEWABLE_MRC50 = 2,
    VIEWABLE_MRC100 = 3,
    VIEWABLE_VIDEO50 = 4,
    SURF_WIN = 500
}

export enum ContextTypes {
    CONTENT = 1,
    SOCIAL = 2,
    PRODUCT = 3
};

export enum ContextSubTypes {
    GENERAL = 10,
    ARTICLE = 11,
    VIDEO = 12,
    AUDIO = 13,
    IMAGE = 14,
    USER_GENERATED = 15,
    GENERAL_SOCIAL = 20,
    EMAIL = 21,
    CHAT = 22,
    PRODUCT = 30,
    APPLICATION = 31,
    PRODUCT_REVIEWS = 32
};

export enum PlacementTypes {
    IN_FEED = 1,
    ATOMIC = 2,
    OUTSIDE_CORE = 3,
    RECOMMENDATION = 4
};

export enum VideoMimes {
    MP4 = 'video/mp4',
    WEBM = 'video/webm'
};

export enum EventTrackingMethods {
    IMG = 1,
    JS = 2
};
