import { ISimpleCreativeComponent } from 'components';
import type { IConfigurationService, IProductInformationService } from 'services';
import type { IBid, IBidRequest, IBidResponse, IProductInformation } from 'models';
import { DecodeAdm, RenderAndBind, RenderCommerceMediaURL } from 'utils';

import template from './SurfBanner.component.hbs';
import watermark from '../watermark/Watermark.component.hbs';
import style from './SurfBanner.component.scss';

export const SURF_PRODUCT_INFO = 'surf-product-info';

/**
 * A creative component which renders a static banner. This is a regular static banner whose clickthrough URL
 * is defined ahead of time and doesn't use handlebars/commerce media
 */
export class SurfBanner extends ISimpleCreativeComponent {
    private readonly _productInformationService: IProductInformationService =
        globalThis.surfside.productInformationService;

    private readonly _publisherConfigurationService: IConfigurationService =
        globalThis.surfside.config;

    public async connectedCallback(): Promise<void> {
        await super.connectedCallback();
    }

    /* Simple bid request that doesn't need any extra services, just send the width and height */
    public async makeRequest(): Promise<IBidRequest> {
        this.logger.debug('SurfBanner.makeRequest');
        const request: IBidRequest = {
            id: this.placementId,
            imp: [{
                id: this.placementId,
                banner: {
                    w: this.width ?? undefined, // must be number or undefined, never null
                    h: this.height ?? undefined
                }
            }]
        };
        if (globalThis.debug) {
            this.logger.debug('SurfBanner.makeRequest test mode');
            request.test = 1;
        }
        return request;
    }

    /* Render the bid response. Simple decodes the ADM and adds it to the shadow dom */
    public async render(bidResponse: IBidResponse, bid: IBid): Promise<void> {
        this.logger.debug('SurfBanner.render', bidResponse);
        const encodedAdm = bid.adm;
        this.logger.debug('SurfBanner.render adm', encodedAdm);
        if (encodedAdm === undefined) {
            this.logger.error('SurfBanner.render adm is undefined. Bidder returned 200 but no adm.');
            return;
        }
        /* Decode the adm. The ADM is sometimes URI encoded, and also the bidder adds a backslash before quotes for
         * some reason, so we need to replace those with regular quotes. */
        let decodedAdm: string;
        try {
            decodedAdm = DecodeAdm(encodedAdm);
        } catch (err) {
            /* If the adm can't be decoded, it may not be encoded at all */
            decodedAdm = encodedAdm;
        }

        /* Render the template with the decoded adm + watermark */
        const rendered = RenderAndBind(template, {
            adm: decodedAdm,
            watermark: watermark(null)
        });

        /* Append nurl as pixel */
        if (bid.nurl !== undefined) {
            const pixel = new Image(1, 1);
            pixel.src = bid.nurl;
            pixel.style.display = 'none';
            this.shadow.appendChild(pixel);
        }

        /* Add the decoded adm to the shadow dom */
        const stylesheet = document.createElement('style');
        stylesheet.innerHTML = style;
        this.shadow.appendChild(stylesheet);
        rendered.childNodes.forEach(n => this.shadow.appendChild(n));

        const clickthrough = this.shadow.querySelector('a[href*=\\{SURF_COMMERCE_URL\\}]');
        if (clickthrough !== null) {
            const clickThroughHref = clickthrough.getAttribute('href');
            if (clickThroughHref === null) {
                this.logger.error('SurfBanner.render clickthrough href is null. Cannot render banner');
                this.remove();
                return;
            }
            const productInfo: HTMLElement | null = this.shadow.querySelector(`div#${SURF_PRODUCT_INFO}`);
            if (productInfo === null) {
                this.logger.error(
                    'SurfBanner.render <div id="productInfo"> is non existent. Cannot render banner because there ' +
                    'is no viable clickthrough URL'
                );
                this.remove();
                return;
            }

            const productId = this.dataset?.productid;
            const catalog = ((productId === null || productId === undefined)
                ? null
                : (await this._productInformationService.getProductById(
                    this.accountId,
                    this.siteId,
                    this.locationId,
                    productId
                )));

            const renderedClickthrough: string | null = await RenderCommerceMediaURL(
                this.accountId,
                this.siteId,
                this.locationId,
                this._getProductFromDataAttributes(productInfo),
                catalog,
                this._publisherConfigurationService,
                this.logger
            );
            if (renderedClickthrough === null) {
                this.logger.error('SurfBanner.render renderedClickthrough is null. Cannot render banner');
                this.remove();
                return;
            }
            const finalClick = clickThroughHref.replace('{SURF_COMMERCE_URL}', renderedClickthrough);
            clickthrough.setAttribute('href', finalClick);
        }
    }

    private _getProductFromDataAttributes(element: HTMLElement): IProductInformation {
        const data = element.dataset;
        return {
            image: data.image ?? null,
            price: data.price ?? null,
            salePrice: data.saleprice ?? null,
            thc: data.thc ?? null,
            strain: data.strain ?? null,
            cbd: data.cbd ?? null,
            name: data.name ?? null,
            brandName: data.brandname ?? null,
            productType: data.producttype ?? null,
            details: data.details ?? null,
            cta: data.cta ?? null,
            clickthroughUrl: data.clickthroughurl ?? null,
            winUrl: data.winurl ?? null,
            pixelUrl: data.pixelurl ?? null,
            ext: data
        };
    }
}
