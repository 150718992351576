import {
    ContextSubTypes,
    ContextTypes,
    NativeAssetId,
    NativeDataTypes,
    PlacementTypes,
    type EventTrackingMethods,
    type EventTypes,
    type VideoMimes
} from 'models';

import type { INativeRequest } from './INativeRequest';

const DEFAULT_LENGTH = 80;
const VAST_3_0 = 3;

export class NativeRequestBuilder {
    private readonly _request: INativeRequest;

    constructor() {
        this._request = {
            ver: '1.2',
            context: ContextTypes.PRODUCT,
            contextsubtype: ContextSubTypes.PRODUCT,
            plcmttype: PlacementTypes.RECOMMENDATION,
            assets: []
        };
    }

    withVer(ver: string): this {
        this._request.ver = ver;
        return this;
    }

    withContext(context: number): this {
        this._request.context = context;
        return this;
    }

    withTitle(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        if (this._request.assets === undefined) {
            this._request.assets = [];
        }
        this._request.assets.push({
            id: 0,
            required: required ? 1 : 0,
            title: {
                len: length
            }
        });
        return this;
    }

    withImage(
        width: number,
        height: number,
        minWidth: number,
        minHeight: number,
        required: boolean = true
    ): this {
        if (this._request.assets === undefined) {
            this._request.assets = [];
        }
        this._request.assets.push({
            id: 0,
            required: required ? 1 : 0,
            img: {
                w: width,
                wmin: minWidth,
                h: height,
                hmin: minHeight
            }
        });
        return this;
    }

    withVideo(
        mimes: VideoMimes[] | string[],
        minDuration: number,
        maxDuration: number,
        required: boolean = true
    ): this {
        if (this._request.assets === undefined) {
            this._request.assets = [];
        }
        this._request.assets.push({
            id: 0,
            required: required ? 1 : 0,
            video: {
                mimes,
                minduration: minDuration,
                maxduration: maxDuration,
                protocols: [VAST_3_0] // VAST 3.0
            }
        });
        return this;
    }

    withData(
        type: NativeDataTypes,
        id: NativeAssetId,
        length: number = DEFAULT_LENGTH,
        required: boolean = true
    ): this {
        if (this._request.assets === undefined) {
            this._request.assets = [];
        }
        this._request.assets.push({
            id,
            required: required ? 1 : 0,
            data: {
                type,
                len: length
            }
        });
        return this;
    }

    withSponsored(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SPONSORED, NativeAssetId.SPONSORED, length, required);
    }

    withDescription(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.DESC, NativeAssetId.DESC, length, required);
    }

    withRating(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.RATING, NativeAssetId.RATING, length, required);
    }

    withPrice(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.PRICE, NativeAssetId.PRICE, length, required);
    }

    withSalePrice(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SALEPRICE, NativeAssetId.SALEPRICE, length, required);
    }

    withDisplayUrl(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.DISPLAYURL, NativeAssetId.DISPLAYURL, length, required);
    }

    withCtaText(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.CTATEXT, NativeAssetId.CTATEXT, length, required);
    }

    withCategory(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SURF_CATEGORY, NativeAssetId.SURF_CATEGORY, length, required);
    }

    withTHC(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SURF_THC, NativeAssetId.SURF_THC, length, required);
    }

    withCBD(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SURF_CBD, NativeAssetId.SURF_CBD, length, required);
    }

    withStrain(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SURF_STRAIN, NativeAssetId.SURF_STRAIN, length, required);
    }

    withMetaProduct(length: number = DEFAULT_LENGTH, required: boolean = true): this {
        return this.withData(NativeDataTypes.SURF_META_PRODUCT, NativeAssetId.SURF_META_PRODUCT, length, required);
    }

    withEventTracker(event: EventTypes, methods: EventTrackingMethods[]): this {
        if (this._request.eventtrackers === undefined) {
            this._request.eventtrackers = [];
        }
        this._request.eventtrackers.push({
            event,
            methods
        });
        return this;
    }

    build(): INativeRequest {
        return this._request;
    }
}
