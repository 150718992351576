import { HttpStatus, Environment } from 'Constants';
import type { IBidRequest, IBidResponse } from 'models';
import { IBidderService, type ILoggerService } from 'services';

const BIDDER_DEBOUNCE_TIME = 100;

/**
 * The Surfside bidder service. Sends a simple HTTP request to the Surfside bidder endpoint.
 */
export class SurfsideBidderService extends IBidderService {
    private readonly _bidderUrl: URL;

    constructor(environment: Environment, logger: ILoggerService) {
        super(logger, BIDDER_DEBOUNCE_TIME);
        this.logger.debug('SurfsideBidderService.constructor initializing', environment);
        switch (environment) {
            case Environment.PROD: {
                this._bidderUrl = new URL(window.location.protocol + '//rtb.surfside.io/rtb/bids/surfside');
                break;
            }
            case Environment.STAGING: {
                this._bidderUrl = new URL(window.location.protocol + '//rtb.surfside.io/rtb/bids/surfside');
                break;
            }
            case Environment.LOCAL: {
                this._bidderUrl = new URL('http://localhost:8080/rtb/bids/surfside');
                break;
            }
            default: { /* MOCK environment uses different BidderService, so this should never be hit */
                this._bidderUrl = new URL('http://localhost:8080/rtb/bids/surfside');
                break;
            }
        }
        this.logger.debug('SurfsideBidderService.constructor initialized', this);
    }

    /* Simply sends the bid to the bidder endpoint, no transformation necessary */
    async sendRequestToService(request: IBidRequest): Promise<IBidResponse> {
        this.logger.debug('SurfsideBidderService.bid', request);
        this.logger.debug('Request debouncer sent bid request from surfside bidder', request.id);
        const response = await fetch(this._bidderUrl, {
            method: 'POST',
            body: JSON.stringify(request)
        });
        this.logger.debug('SurfsideBidderService.bid response', response);

        this.logger.debug('SurfsideBidderService.bid response status', response.status);
        /* Bidder returns 204 No Content when it has no bid response */
        if (response.status !== HttpStatus.OK) {
            /* No Content may return an X-REASON header print the reason the bid request failed
             * if the id of the request is set to "123". Most of the reason strings are useless
             * but sometimes they're useful. */
            const details: string | null = response.status === HttpStatus.NO_CONTENT
                ? response.headers.get('X-REASON')
                : await response.text();
            if (details !== null) {
                this.logger.debug('SurfsideBidderService.bid response no-bid reason:', details);
            }
            throw 'No bid response';
        }

        /* Deserialize and return */
        const bidResponse = await response.json();
        this.logger.debug('SurfsideBidderService.bid returning', bidResponse);
        return bidResponse;
    }
}
