export interface IBid {
    id: string;
    impid: string;
    price: number;
    nurl?: string;
    burl?: string;
    lurl?: string;
    adm?: string;
    adid?: string;
    adomain?: string[];
    bundle?: string;
    iurl?: string;
    cid?: string;
    crid?: string;
    tactic?: string;
    cattax?: number;
    cat?: string[];
    attr?: number[];
    api?: number;
    protocol?: number;
    qagmediarating?: number;
    language?: string;
    dealid?: string;
    w?: number;
    h?: number;
    wratio?: number;
    hratio?: number;
    exp?: number;
    ext?: any;
    dur?: number;
    mtype?: number;
    slotinpid?: number;
}

export interface ISeatBid {
    bid: IBid[];
    seat?: string;
    group?: number;
    ext?: any;
}

export interface IBidResponse {
    id: string;
    seatbid?: ISeatBid[];
    bidid?: string;
    cur?: string;
    nbr?: number;
    ext?: any;
}
