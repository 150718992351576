import { ICreativeComponent } from 'components';

/**
 * This class represents a simple creative component which has a width, height, and interstitial flag.
 * This is intended for banners and videos, which both have these properties.
 */
export abstract class ISimpleCreativeComponent extends ICreativeComponent {
    protected width: number = 0;
    protected height: number = 0;

    public async init(): Promise<void> {
        await super.init();

        this.logger.debug('ISimpleCreativeComponent.constructor initializing');
        try {
            this.width = this.numberOrError(this.getAttribute('width'));
        } catch (err) {
            throw new Error(
                'ISimpleCreativeComponent.constructor error on attribute "width"; attribute must be a number'
            );
        }
        try {
            this.height = this.numberOrError(this.getAttribute('height'));
        } catch (err) {
            throw new Error(
                'ISimpleCreativeComponent.constructor error on attribute "height"; attribute must be a number'
            );
        }
        this.logger.debug('ISimpleCreativeComponent.constructor loaded attributes', this);
    }
}
