var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"product-card\">\n    <img src=\""
    + ((stack1 = (lookupProperty(helpers,"coalesce")||(depth0 && lookupProperty(depth0,"coalesce"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"image") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"catalog") : depth0)) != null ? lookupProperty(stack1,"image") : stack1),{"name":"coalesce","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":54}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"product-info\">\n        <h1>"
    + ((stack1 = (lookupProperty(helpers,"coalesce")||(depth0 && lookupProperty(depth0,"coalesce"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"catalog") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"coalesce","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":50}}})) != null ? stack1 : "")
    + "</h1>\n        <p>"
    + ((stack1 = (lookupProperty(helpers,"coalesce")||(depth0 && lookupProperty(depth0,"coalesce"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"product") : depth0)) != null ? lookupProperty(stack1,"details") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"catalog") : depth0)) != null ? lookupProperty(stack1,"details") : stack1),{"name":"coalesce","hash":{},"data":data,"loc":{"start":{"line":5,"column":11},"end":{"line":5,"column":55}}})) != null ? stack1 : "")
    + "</p>\n        <a href=\"#\" _click=\"click\">Click here</a>\n    </div>\n</div>\n";
},"useData":true});