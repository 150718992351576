import type { ILoggerService } from 'services';
import type {
    IPublisherConfiguration,
    IProductInformation,
    ICompiledPublisherConfiguration,
    IProductContext
} from 'models';
import {
    slug,
    lower,
    path,
    search,
    coalesce,
    join,
    uri,
    eq,
    or,
    and,
    isIn,
    array
} from './Helpers';
import type Handlebars from 'handlebars/runtime';
export * from './CommerceMedia';

/* Two fields in the following interfaces have snake case. This is required for the handlebars helpers, so the
 * eslint rules are disabled for those specific lines */

export interface INativeTemplateDelegateWindow {
    origin: string;
    port: string;
    path: string;
    host: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    host_name: string;
    search: string;
    hash: string;
    protocol: string;
}

export interface INativeTemplateDelegateData {
    product: Partial<IProductInformation>;
    window: INativeTemplateDelegateWindow;
    publisher: IPublisherConfiguration;
    catalog: IProductInformation;
    ext: any;
};
export type NativeTemplateDelegate = HandlebarsTemplateDelegate<INativeTemplateDelegateData>;

/**
 * Initializes Handlebars with all custom helpers
 *
 * @param logger The logger service for debug messages
 */
export function initHandlebars(logger: ILoggerService): void {
    logger.info('InitHandlebars initializing');

    registerHelper('slug', slug, logger);
    registerHelper('lower', lower, logger);
    registerHelper('path', path, logger);
    registerHelper('search', search, logger);
    registerHelper('coalesce', coalesce, logger);
    registerHelper('join', join, logger);
    registerHelper('uri', uri, logger);
    registerHelper('eq', eq, logger);
    registerHelper('or', or, logger);
    registerHelper('and', and, logger);
    registerHelper('in', isIn, logger);
    registerHelper('array', array, logger);
}

/**
 * Registers a Handlebars helper with the given name, and prints a debug message to the logger
 *
 * @param name The name of the helper
 * @param helper The helper function
 * @param logger The logger service for debug messages
 */
function registerHelper(name: string, helper: Handlebars.HelperDelegate, logger: ILoggerService): void {
    logger.debug(`Registering Handlebars Helper ${name}`);
    globalThis.handlebars.registerHelper(name, helper);
}

/**
 * Returns a NativeTemplateDelegateWindow object with the current window location properties
 *
 * @returns A NativeTemplateDelegateWindow object with the current window location properties
 */
function getNativeTemplateDelegateWindow(): INativeTemplateDelegateWindow {
    const location = window.location;
    return {
        origin: location.origin,
        port: location.port,
        path: location.pathname,
        host: location.host,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        host_name: location.hostname,
        search: location.search,
        hash: location.hash,
        protocol: location.protocol
    };
}

/**
 * Renders a URL template using the provided delegate, native slot, and publisher configuration.
 * Gets the window location properties from the current window.
 *
 * @param delegate The Handlebars delegate function to render the template
 * @param productInformation The native slot object to render
 * @param publisherConfiguration The publisher configuration object to render
 * @returns The rendered URL template
 */
export function renderTemplate(
    delegate: HandlebarsTemplateDelegate<IProductContext>,
    productInformation: IProductInformation | undefined,
    publisherConfiguration: ICompiledPublisherConfiguration,
    catalogProduct: IProductInformation = {
        image: null,
        price: null,
        salePrice: null,
        thc: null,
        cbd: null,
        strain: null,
        name: null,
        brandName: null,
        productType: null,
        details: null,
        cta: null,
        clickthroughUrl: null,
        winUrl: null,
        pixelUrl: null,
        ext: null
    }
): string {
    const nativeTemplateDelegateWindow = getNativeTemplateDelegateWindow();
    return delegate({
        product: productInformation ?? {},
        window: nativeTemplateDelegateWindow,
        catalog: catalogProduct,
        publisher: publisherConfiguration,
        ext: productInformation?.ext
    }, {
        helpers: {
            slug,
            lower,
            path,
            search,
            coalesce,
            join,
            uri,
            eq,
            or,
            and,
            isIn,
            array
        }
    });
}

function bindEventHandlers<T>(element: Element, t: T, eventName: string): void {
    const bindable = Array.from(element.querySelectorAll(`[_${eventName}_]`));
    for (const e of bindable) {
        const handler = t[e.getAttribute(`_${eventName}_`) as keyof T] as (e: Event) => void;
        e.addEventListener(eventName, handler);
    }
}

export function RenderAndBind<T>(template: (t: T) => string, t: T): Element {
    const html = template(t);
    const wrapper = document.createElement('div');
    wrapper.innerHTML = html;
    bindEventHandlers(wrapper, t, 'click');
    bindEventHandlers(wrapper, t, 'mousedown');
    bindEventHandlers(wrapper, t, 'loadeddata');
    bindEventHandlers(wrapper, t, 'error');
    bindEventHandlers(wrapper, t, 'touchend');
    bindEventHandlers(wrapper, t, 'touchstart');
    bindEventHandlers(wrapper, t, 'touchmove');
    return wrapper;
}
