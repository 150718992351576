import type { ILoggerService } from './ILoggerService';
import { type ISnowplowInit } from './ISnowplowInit';

export class SnowplowInitService implements ISnowplowInit {
    constructor(private readonly _logger: ILoggerService) {
        this._logger.debug('SurfsideCookieService.constructor');
    }

    initSnowplow(): void {
        if (window.surf !== undefined && !window.hasSnowplowTracker) {
            this._logger.debug('Initializing Snowplow tracker');
            window.surf('newTracker', 'ads', '//col.surfside.io', {
                appId: 'ads',
                cookieName: 'surf',
                cookieDomain: 'surfside.io'
            });
            window.hasSnowplowTracker = true;
        } else if (window.surf !== undefined && window.hasSnowplowTracker) {
            this._logger.debug('Snowplow tracker already initialized');
            window.surf('trackPageView');
        }
    }
};
