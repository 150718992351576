export interface IBrandVersion {
    brand: string;
    version?: string[];
    ext?: any;
}

export interface IUserAgent {
    browsers?: IBrandVersion[];
    platform?: IBrandVersion;
    mobile?: number;
    architecure?: string;
    bitness?: string;
    model?: string;
    source?: number;
    ext?: any;
}

export enum AgentTypes {
    SESSION = 1,
    DEVICE = 2,
    USER = 3
}
export interface IUid {
    id: string;
    atype?: AgentTypes;
    ext?: any;
}

export interface IEid {
    source: string;
    uids: IUid[];
    ext?: any;
}

export interface ISupplyChainNode {
    asi?: string;
    sid?: string;
    hp?: number;
    rid?: string;
    name?: string;
    domain?: string;
    ext?: any;
}

export interface ISupplyChain {
    complete: number;
    nodes: ISupplyChainNode[];
    ver: string;
    ext?: any;
}

export interface IChannel {
    id?: string;
    name?: string;
    domain?: string;
    ext?: any;
}

export interface INetwork {
    id?: string;
    name?: string;
    domain?: string;
    ext?: any;
}

export interface ISegment {
    id?: string;
    name?: string;
    value?: string;
    ext?: any;
}

export interface IData {
    id?: string;
    name?: string;
    segment?: ISegment[];
    ext?: any;
}

export interface IUser {
    id?: string;
    buyeruid?: string;
    yob?: number;
    gender?: string;
    keywords?: string;
    kwarray?: string[];
    customdata?: string;
    geo?: IGeo;
    data?: IData[];
    cconsent?: string;
    eids?: IEid[];
    ext?: any;
}

export interface IGeo {
    lat?: number;
    lon?: number;
    type?: number;
    accuracy?: number;
    lastfix?: number;
    ipservice?: number;
    country?: string;
    region?: string;
    regionfips104?: string;
    metro?: string;
    city?: string;
    zip?: string;
    utcoffset?: number;
    ext?: any;
}

export interface IDevice {
    ua?: string;
    geo?: IGeo;
    dnt?: number;
    lmt?: number;
    ip?: string;
    ipv6?: string;
    devicetype?: number;
    make?: string;
    model?: string;
    os?: string;
    osv?: string;
    hwv?: string;
    h?: number;
    w?: number;
    ppi?: number;
    pxratio?: number;
    js?: number;
    geofetch?: number;
    flashver?: string;
    language?: string;
    lanbg?: string;
    carrier?: string;
    mccmnc?: string;
    connectiontype?: number;
    ifa?: string;
    didsha1?: string;
    didmd5?: string;
    dpidsha1?: string;
    dpidmd5?: string;
    macsha1?: string;
    macmd5?: string;
    ext?: any;
}

export interface IProducer {
    id?: string;
    name?: string;
    cattax?: number[];
    cat?: string[];
    domain?: string;
    ext?: any;
}

export interface IContent {
    id?: string;
    episode?: number;
    title?: string;
    series?: string;
    season?: string;
    artist?: string;
    genre?: string;
    album?: string;
    isrc?: string;
    producer?: IProducer;
    url?: string;
    cattax?: number[];
    cat?: string[];
    prodq?: number;
    videoquality?: number;
    context?: number;
    contentrating?: string;
    userrating?: string;
    qagmediarating?: number;
    keywords?: string;
    kwarray?: string[];
    livestream?: number;
    sourcerelationship?: number;
    len?: number;
    language?: string;
    langb?: string;
    embeddable?: number;
    data?: IData[];
    network?: INetwork;
    channel?: IChannel;
    ext?: any;
}

export interface IPublisher {
    id?: string;
    name?: string;
    cattax?: number[];
    cat?: string[];
    domain?: string;
    ext?: any;
}

export interface IApp {
    id?: string;
    name?: string;
    bundle?: string;
    domain?: string;
    storeurl?: string;
    cat?: string[];
    sectioncat?: string[];
    pagecat?: string[];
    ver?: string;
    privacypolicy?: number;
    paid?: number;
    publisher?: IPublisher;
    content?: IContent;
    keywords?: string;
    kwarray?: string[];
    ext?: any;
}

export interface ISite {
    id?: string;
    name?: string;
    domain?: string;
    cat?: string[];
    sectioncat?: string[];
    pagecat?: string[];
    page?: string;
    ref?: string;
    search?: string;
    mobile?: number;
    privacypolicy?: number;
    publisher?: IPublisher;
    content?: IContent;
    keywords?: string;
    kwarray?: string[];
    ext?: any;
}

export interface IDeal {
    id: string;
    bidfloor: number;
    bidfloorcur?: string;
    at?: number;
    wseat?: string[];
    wadomain?: string[];
    ext?: any;
}

export interface IPmp {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private_auction?: number;
    deals?: IDeal[];
    ext?: any;
}

export interface IFormat {
    w?: number;
    h?: number;
    wratio?: number;
    hratio?: number;
    wmin?: number;
    ext?: any;
}

export interface INative {
    request: string;
    ver?: string;
    api?: number[];
    battr?: number[];
    ext?: any;
}

export interface IAudio {
    mimes: string[];
    minduration?: number;
    maxduration?: number;
    poddur?: number;
    protocols?: number[];
    startdelay?: number;
    rqddurs?: number[];
    podid?: string;
    podseq?: number;
    sequence?: number;
    slotinpod?: number;
    mincpmpersec?: number;
    battr?: number[];
    maxextended?: number;
    minbitrate?: number;
    maxbitrate?: number;
    delivery?: number[];
    companionad?: IBanner[];
    api?: number[];
    companiontype?: number[];
    maxseq?: number;
    feed?: number;
    stitched?: number;
    nvol?: number;
    ext?: any;
}

export interface IVideo {
    mimes: string[];
    minduration?: number;
    maxduration?: number;
    startdelay?: number;
    maxseq?: number;
    poddur?: number;
    protocols?: number[];
    w?: number;
    h?: number;
    podid?: string;
    podseq?: number;
    rqddurs?: number[];
    placement?: number;
    linearity?: number;
    skip?: number;
    skipmin?: number;
    skipafter?: number;
    sequence?: number;
    slotinpod?: number;
    mincpmpersec?: number;
    battr?: number[];
    maxextended?: number;
    minbitrate?: number;
    maxbitrate?: number;
    boxingallowed?: number;
    playbackmethod?: number[];
    playbackend?: number;
    delivery?: number[];
    pos?: number;
    companionad?: IBanner[];
    api?: number[];
    companiontype?: number[];
    ext?: any;
}

export interface IBanner {
    format?: IFormat[];
    w?: number;
    h?: number;
    btype?: number[];
    battr?: number[];
    pos?: number;
    mimes?: string[];
    topframe?: number;
    expdir?: number[];
    api?: number[];
    id?: string;
    ext?: any;
}

export interface IMetric {
    type: string;
    value: number;
    vendor?: string;
    ext?: any;
}

export interface IImp {
    id: string;
    metric?: IMetric[];
    banner?: IBanner;
    video?: IVideo;
    audio?: IAudio;
    native?: INative;
    pmp?: IPmp;
    displaymanager?: string;
    displaymanagerver?: string;
    instl?: number;
    tagid?: string;
    bidfloor?: number;
    bidfloorcur?: string;
    clickbrowser?: number;
    secure?: number;
    iframebuster?: string[];
    rwdd?: number;
    ssai?: number;
    exp?: number;
    ext?: any;
}

export interface IRegs {
    coppa?: number;
    ext?: any;
    gdpr?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    us_privacy?: string;
}

export interface ISource {
    fd?: number;
    tid?: string;
    pchain?: string;
    schain?: ISupplyChain;
    ext?: any;
}

export interface IBidRequest {
    id: string;
    imp: IImp[];
    site?: ISite;
    app?: IApp;
    device?: IDevice;
    user?: IUser;
    test?: number;
    at?: number;
    tmax?: number;
    wseat?: string[];
    bseat?: string[];
    allimps?: number;
    cur?: string[];
    wlang?: string[];
    wlangb?: string[];
    bcat?: string[];
    cattax?: number[];
    badv?: string[];
    bapp?: string[];
    source?: ISource;
    regs?: IRegs;
    ext?: any;
}
