import type { IBidResponse } from 'models';
import type { IResponseMiddleware } from 'middleware';

export class PriceNurlMiddleware implements IResponseMiddleware {
    async interceptResponse(response: IBidResponse): Promise<IBidResponse> {
        response.seatbid?.forEach(seatbid => {
            seatbid.bid?.forEach(bid => {
                if (bid.price !== undefined) {
                    bid.nurl = bid.nurl?.replace(
                        /(?:\$|%24)?(?:\{|%7b)auction_price(?:\}|%7d)/ig, /* Sometimes it's URI encoded */
                        bid.price.toString()
                    );
                }
            });
        });
        return response;
    }
}
