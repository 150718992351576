import { type IBidResponse } from 'models';
import type {
    ILoggerService
} from 'services';
import type { IResponseMiddleware } from 'middleware';
import type { ISnowplowBidderDoneEvent } from 'models/rtb/ISnowplowTypes';

export class SnowplowResponseMiddleware implements IResponseMiddleware {
    private readonly _logger: ILoggerService = globalThis.surfside.logger;

    async interceptResponse(
        response: IBidResponse
    ): Promise<IBidResponse> {
        this._logger.debug('SnowplowResponseMiddleware.intercept', response);
        const seatbidBids = response?.seatbid !== undefined ? response.seatbid[0]?.bid : [];

        const snowplowBidderDoneEvent: ISnowplowBidderDoneEvent = {
            auctionId: response.id,
            bidderRequestId: response.id,
            bids: seatbidBids.map(bid => {
                return {
                    params: {
                        accountId: response.ext?.accountId ?? null,
                        channelId: response.ext?.channelId ?? null,
                        siteId: response.ext?.siteId ?? null,
                        placementId: response.ext?.placementId ?? null,
                        zone: response.ext?.zone ?? null,
                        type: response.ext?.type ?? null,
                        storeId: response.ext?.storeId ?? null
                    },
                    bidId: bid.id,
                    transactionId: bid.impid,
                    ortb2: {
                        site: {
                            domain: window.location.hostname,
                            page: window.location.pathname
                        },
                        device: {
                            w: window.screen.width,
                            h: window.screen.height,
                            dnt: 0,
                            ua: window.navigator.userAgent,
                            language: window.navigator.language
                        }
                    },
                    ortb2Imp: {},
                    sizes: bid.w !== undefined && bid.h !== undefined
                        ? [bid.w, bid.h]
                        : [0, 0],
                    mediaTypes: bid.w !== undefined && bid.h !== undefined
                        ? {
                            banner: {
                                sizes: [bid.w, bid.h]
                            }
                        }
                        : {}
                };
            }),
            auctionStart: 0,
            refererInfo: {
                stack: [document.referrer],
                location: window.location.href,
                page: window.location.href,
                domain: window.location.hostname
            },
            timeout: 0,
            start: Date.now()
        };

        if (window.surf !== undefined) {
            this._logger.debug('Sending bidderDone event to Snowplow', snowplowBidderDoneEvent);
            window.surf('bidderDone:ads', snowplowBidderDoneEvent);
        }
        this._logger.debug('SnowplowResponseMiddleware.intercept returning', response);
        return response;
    }
}
