import {
    NativeAssetId,
    NativeImageTypes,
    EventTypes,
    EventTrackingMethods,
    type IBanner,
    type IBidResponse,
    type INativeResponse,
    type IBidRequest,
    type INativeRequest,
    type IProductInformation
} from 'models';
import { IBidderService, type ILoggerService } from 'services';
import { SurfColors } from 'Constants';
import { SURF_PRODUCT_INFO } from 'components/surf-banner/SurfBanner.component';

const FALLBACK_WIDTH = 728;
const FALLBACK_HEIGHT = 90;

const NATIVE_SIZE = 350;

const FAKE_PRODUCTS = [
    {
        account_id: 123,
        source_id: 456,
        location_id: 789,
        item_id: 'cannabis-001',
        name: 'Blue Dream',
        list: 'Cannabis Catalog',
        brand: 'GreenLeaf',
        category: 'Flower',
        variant: 'Hybrid',
        price: 45.00,
        quantity: 100,
        coupon: 'SAVE10',
        position: 'Best Seller',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Blue Dream',
        thc_content: '18%',
        cbd_content: '2%',
        terpenes: ['Myrcene', 'Pinene', 'Caryophyllene'],
        condition: 'Fresh',
        cost_of_goods_sold: 30,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup123',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CA',
        zip: '90210',
        description: 'A popular hybrid strain known for its balanced effects.',
        link: 'http://example.com/blue-dream',
        image_link: 'http://example.com/images/blue-dream.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-01',
        expiration_date: '2025-11-01',
        sale_price: 40.00,
        sale_price_effective_date: '2024-11-15',
        mpn: 'BD123',
        gtin: '1234567890123'
    },
    {
        account_id: 124,
        source_id: 457,
        location_id: 790,
        item_id: 'cannabis-002',
        name: 'Granddaddy Purple',
        list: 'Cannabis Catalog',
        brand: 'PurpleHaze',
        category: 'Flower',
        variant: 'Indica',
        price: 50.00,
        quantity: 80,
        coupon: 'PURPLE20',
        position: 'Customer Favorite',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Granddaddy Purple',
        thc_content: '22%',
        cbd_content: '1%',
        terpenes: ['Linalool', 'Myrcene', 'Caryophyllene'],
        condition: 'Fresh',
        cost_of_goods_sold: 35,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup124',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CO',
        zip: '80014',
        description: 'An indica strain with a sweet grape aroma.',
        link: 'http://example.com/granddaddy-purple',
        image_link: 'http://example.com/images/granddaddy-purple.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-05',
        expiration_date: '2025-11-05',
        sale_price: 45.00,
        sale_price_effective_date: '2024-11-20',
        mpn: 'GDP124',
        gtin: '1234567890124'
    },
    {
        account_id: 125,
        source_id: 458,
        location_id: 791,
        item_id: 'cannabis-003',
        name: 'Sour Diesel',
        list: 'Cannabis Catalog',
        brand: 'SunGrow',
        category: 'Flower',
        variant: 'Sativa',
        price: 55.00,
        quantity: 60,
        coupon: 'DIESEL15',
        position: 'Staff Pick',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Sour Diesel',
        thc_content: '20%',
        cbd_content: '0.5%',
        terpenes: ['Limonene', 'Caryophyllene', 'Myrcene'],
        condition: 'Fresh',
        cost_of_goods_sold: 40,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup125',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'OR',
        zip: '97035',
        description: 'A sativa strain known for its energizing effects.',
        link: 'http://example.com/sour-diesel',
        image_link: 'http://example.com/images/sour-diesel.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-10',
        expiration_date: '2025-11-10',
        sale_price: 50.00,
        sale_price_effective_date: '2024-11-25',
        mpn: 'SD125',
        gtin: '1234567890125'
    },
    {
        account_id: 126,
        source_id: 459,
        location_id: 792,
        item_id: 'cannabis-004',
        name: 'Pineapple Express',
        list: 'Cannabis Catalog',
        brand: 'TropicalGreen',
        category: 'Flower',
        variant: 'Hybrid',
        price: 48.00,
        quantity: 90,
        coupon: 'TROPIC10',
        position: 'Trending',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Pineapple Express',
        thc_content: '19%',
        cbd_content: '1%',
        terpenes: ['Pinene', 'Caryophyllene', 'Humulene'],
        condition: 'Fresh',
        cost_of_goods_sold: 33,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup126',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'WA',
        zip: '98101',
        description: 'A hybrid strain with tropical flavors.',
        link: 'http://example.com/pineapple-express',
        image_link: 'http://example.com/images/pineapple-express.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-15',
        expiration_date: '2025-11-15',
        sale_price: 43.00,
        sale_price_effective_date: '2024-12-01',
        mpn: 'PE126',
        gtin: '1234567890126'
    },
    {
        account_id: 127,
        source_id: 460,
        location_id: 793,
        item_id: 'cannabis-005',
        name: 'Girl Scout Cookies',
        list: 'Cannabis Catalog',
        brand: 'CookieFarm',
        category: 'Flower',
        variant: 'Hybrid',
        price: 52.00,
        quantity: 70,
        coupon: 'COOKIE5',
        position: 'New Arrival',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Girl Scout Cookies',
        thc_content: '21%',
        cbd_content: '0.7%',
        terpenes: ['Caryophyllene', 'Limonene', 'Humulene'],
        condition: 'Fresh',
        cost_of_goods_sold: 37,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup127',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'NV',
        zip: '89109',
        description: 'A hybrid strain with sweet and earthy flavors.',
        link: 'http://example.com/girl-scout-cookies',
        image_link: 'http://example.com/images/girl-scout-cookies.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-20',
        expiration_date: '2025-11-20',
        sale_price: 47.00,
        sale_price_effective_date: '2024-12-05',
        mpn: 'GSC127',
        gtin: '1234567890127'
    },
    {
        account_id: 128,
        source_id: 461,
        location_id: 794,
        item_id: 'cannabis-006',
        name: 'OG Kush',
        list: 'Cannabis Catalog',
        brand: 'HighMountain',
        category: 'Flower',
        variant: 'Hybrid',
        price: 49.00,
        quantity: 85,
        coupon: 'OGKUSH5',
        position: 'Classic',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'OG Kush',
        thc_content: '20%',
        cbd_content: '0.5%',
        terpenes: ['Myrcene', 'Limonene', 'Caryophyllene'],
        condition: 'Fresh',
        cost_of_goods_sold: 34,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup128',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CA',
        zip: '90001',
        description: 'A legendary strain known for its relaxing effects.',
        link: 'http://example.com/og-kush',
        image_link: 'http://example.com/images/og-kush.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-25',
        expiration_date: '2025-11-25',
        sale_price: 44.00,
        sale_price_effective_date: '2024-12-10',
        mpn: 'OGK128',
        gtin: '1234567890128'
    },
    {
        account_id: 129,
        source_id: 462,
        location_id: 795,
        item_id: 'cannabis-007',
        name: 'White Widow',
        list: 'Cannabis Catalog',
        brand: 'SnowBud',
        category: 'Flower',
        variant: 'Hybrid',
        price: 51.00,
        quantity: 75,
        coupon: 'WHITEWIDOW',
        position: 'Award Winner',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'White Widow',
        thc_content: '19%',
        cbd_content: '1%',
        terpenes: ['Myrcene', 'Pinene', 'Limonene'],
        condition: 'Fresh',
        cost_of_goods_sold: 36,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup129',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'MI',
        zip: '48201',
        description: 'A balanced hybrid with uplifting effects.',
        link: 'http://example.com/white-widow',
        image_link: 'http://example.com/images/white-widow.jpg',
        availability: 'In Stock',
        availability_date: '2024-11-30',
        expiration_date: '2025-11-30',
        sale_price: 46.00,
        sale_price_effective_date: '2024-12-15',
        mpn: 'WW129',
        gtin: '1234567890129'
    },
    {
        account_id: 130,
        source_id: 463,
        location_id: 796,
        item_id: 'cannabis-008',
        name: 'Jack Herer',
        list: 'Cannabis Catalog',
        brand: 'HerbalEssence',
        category: 'Flower',
        variant: 'Sativa',
        price: 53.00,
        quantity: 65,
        coupon: 'JACKH10',
        position: 'Top Rated',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Jack Herer',
        thc_content: '18%',
        cbd_content: '0.8%',
        terpenes: ['Terpinolene', 'Caryophyllene', 'Pinene'],
        condition: 'Fresh',
        cost_of_goods_sold: 38,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup130',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'OR',
        zip: '97201',
        description: 'A sativa-dominant strain offering clear-headed effects.',
        link: 'http://example.com/jack-herer',
        image_link: 'http://example.com/images/jack-herer.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-05',
        expiration_date: '2025-12-05',
        sale_price: 48.00,
        sale_price_effective_date: '2024-12-20',
        mpn: 'JH130',
        gtin: '1234567890130'
    },
    {
        account_id: 131,
        source_id: 464,
        location_id: 797,
        item_id: 'cannabis-009',
        name: 'Northern Lights',
        list: 'Cannabis Catalog',
        brand: 'AuroraCannabis',
        category: 'Flower',
        variant: 'Indica',
        price: 54.00,
        quantity: 70,
        coupon: 'NLIGHTS',
        position: 'Customer Favorite',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Northern Lights',
        thc_content: '21%',
        cbd_content: '0.5%',
        terpenes: ['Myrcene', 'Caryophyllene', 'Limonene'],
        condition: 'Fresh',
        cost_of_goods_sold: 39,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup131',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CO',
        zip: '80202',
        description: 'A pure indica strain famous for its relaxing effects.',
        link: 'http://example.com/northern-lights',
        image_link: 'http://example.com/images/northern-lights.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-10',
        expiration_date: '2025-12-10',
        sale_price: 49.00,
        sale_price_effective_date: '2024-12-25',
        mpn: 'NL131',
        gtin: '1234567890131'
    },
    {
        account_id: 132,
        source_id: 465,
        location_id: 798,
        item_id: 'cannabis-010',
        name: 'Super Lemon Haze',
        list: 'Cannabis Catalog',
        brand: 'CitrusFarm',
        category: 'Flower',
        variant: 'Sativa',
        price: 47.00,
        quantity: 95,
        coupon: 'LEMONADE',
        position: 'Fresh Arrival',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Super Lemon Haze',
        thc_content: '19%',
        cbd_content: '0.6%',
        terpenes: ['Limonene', 'Terpinolene', 'Caryophyllene'],
        condition: 'Fresh',
        cost_of_goods_sold: 32,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup132',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'WA',
        zip: '98004',
        description: 'A zesty sativa with lemony freshness.',
        link: 'http://example.com/lemon-haze',
        image_link: 'http://example.com/images/lemon-haze.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-15',
        expiration_date: '2025-12-15',
        sale_price: 42.00,
        sale_price_effective_date: '2024-12-30',
        mpn: 'LH132',
        gtin: '1234567890132'
    },
    {
        account_id: 133,
        source_id: 466,
        location_id: 799,
        item_id: 'cannabis-011',
        name: 'Bubba Kush',
        list: 'Cannabis Catalog',
        brand: 'KushKing',
        category: 'Flower',
        variant: 'Indica',
        price: 50.00,
        quantity: 80,
        coupon: 'BUBBAKUSH',
        position: 'Staff Pick',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Bubba Kush',
        thc_content: '22%',
        cbd_content: '0.3%',
        terpenes: ['Myrcene', 'Caryophyllene', 'Linalool'],
        condition: 'Fresh',
        cost_of_goods_sold: 35,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup133',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CA',
        zip: '94102',
        description: 'An indica strain with a rich, earthy aroma.',
        link: 'http://example.com/bubba-kush',
        image_link: 'http://example.com/images/bubba-kush.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-20',
        expiration_date: '2025-12-20',
        sale_price: 45.00,
        sale_price_effective_date: '2025-01-05',
        mpn: 'BK133',
        gtin: '1234567890133'
    },
    {
        account_id: 134,
        source_id: 467,
        location_id: 800,
        item_id: 'cannabis-012',
        name: 'Durban Poison',
        list: 'Cannabis Catalog',
        brand: 'AfricanHerb',
        category: 'Flower',
        variant: 'Sativa',
        price: 56.00,
        quantity: 60,
        coupon: 'DURBAN5',
        position: 'Exclusive',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Durban Poison',
        thc_content: '20%',
        cbd_content: '0.2%',
        terpenes: ['Terpinolene', 'Ocimene', 'Myrcene'],
        condition: 'Fresh',
        cost_of_goods_sold: 41,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup134',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'OR',
        zip: '97401',
        description: 'A pure sativa known for its uplifting effects.',
        link: 'http://example.com/durban-poison',
        image_link: 'http://example.com/images/durban-poison.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-25',
        expiration_date: '2025-12-25',
        sale_price: 51.00,
        sale_price_effective_date: '2025-01-10',
        mpn: 'DP134',
        gtin: '1234567890134'
    },
    {
        account_id: 135,
        source_id: 468,
        location_id: 801,
        item_id: 'cannabis-013',
        name: 'Strawberry Cough',
        list: 'Cannabis Catalog',
        brand: 'BerryGood',
        category: 'Flower',
        variant: 'Sativa',
        price: 52.00,
        quantity: 70,
        coupon: 'STRAWBERRY',
        position: 'Popular',
        currency: 'USD',
        product_type: 'Cannabis',
        product_category: 'Flower',
        strain: 'Strawberry Cough',
        thc_content: '18%',
        cbd_content: '0.4%',
        terpenes: ['Limonene', 'Myrcene', 'Pinene'],
        condition: 'Fresh',
        cost_of_goods_sold: 37,
        shipping: 0.00,
        shipping_weight: 0.05,
        item_group_id: 'FlowerGroup135',
        age_group: 'Adult',
        adult: true,
        restricted: true,
        country: 'USA',
        state: 'CO',
        zip: '80302',
        description: 'A sweet sativa with strawberry aroma.',
        link: 'http://example.com/strawberry-cough',
        image_link: 'http://example.com/images/strawberry-cough.jpg',
        availability: 'In Stock',
        availability_date: '2024-12-30',
        expiration_date: '2025-12-30',
        sale_price: 47.00,
        sale_price_effective_date: '2025-01-15',
        mpn: 'SC135',
        gtin: '1234567890135'
    }
];

/**
 * A mock bidder service that returns a simple image banner for any bid request. The image is a gradient with the
 * dimensions of the requested banner size, and contains the text of the banner size in the center. The image is
 * base64 encoded and returned as the adm field of the bid response. The image is created using a canvas element and
 * not a real bidder service. Currently, only static creatives are supported.
 */
export class MockBidderService extends IBidderService {
    /**
     * Each key in this map is a bid request ID. Each value is its own map.
     * The keys of the child-map are impression IDs WITHIN a bid request,
     * and the values of that are promises to be resolved
     */
    constructor(protected readonly logger: ILoggerService) {
        super(logger);
        this.logger.debug('MockBidderService.constructor');
    }

    public async sendRequestToService(bidRequest: IBidRequest): Promise<IBidResponse> {
        this.logger.debug('MockBidderService.sendRequestToService total imps', bidRequest.imp.length);
        const responses: IBidResponse[] = [];
        const returned = bidRequest.imp.length;

        for (let i = 0; i < returned; i++) {
            const imp = bidRequest.imp[i];
            if (imp.video !== undefined) {
                responses.push(await this._makeVideo(bidRequest.id, imp.id));
            } else if (imp.native !== undefined) {
                const nativeRequest: INativeRequest = JSON.parse(imp.native.request);
                responses.push(await this._makeNative(
                    bidRequest.id,
                    imp.id,
                    nativeRequest.assets.some(asset => asset.id === NativeAssetId.SURF_META_PRODUCT)
                ));
            } else if (imp.banner !== undefined) {
                responses.push(await this._makeBanner(bidRequest.id, imp.id, imp.banner));
            }
        }
        const response = this._flattenResponses(responses);
        this.logger.debug(
            'MockBidderService.sendRequestToService returning total bids',
            response?.seatbid?.[0].bid.length ?? 0
        );
        return response;
    }

    private _flattenResponses(responses: IBidResponse[]): IBidResponse {
        const bids = responses.flatMap(response => (response.seatbid ?? []).flatMap(seatbid => seatbid.bid));
        return {
            ...(responses[0]),
            seatbid: [{
                ...(responses[0].seatbid?.[0] ?? {}),
                bid: bids
            }]
        };
    }

    private async _makeVideo(requestId: string, impid: string): Promise<IBidResponse> {
        return {
            id: requestId,
            seatbid: [{
                bid: [{
                    id: impid,
                    impid,
                    price: 3.50,
                    adid: '123456',
                    adm: this._makeVast(),
                    nurl: 'https://www.surfside.io/win-notice'
                }]
            }]
        };
    }

    private _makeVast(): string {
        return `<?xml version="1.0" encoding="utf-8"?>
        <VAST version="3.0">
            <Ad id="preroll">
                <InLine>
                    <Impression id="impression"><![CDATA[http://localhost:3005/impression]]></Impression>
                    <AdSystem><![CDATA[2.0]]></AdSystem>
                    <AdTitle><![CDATA[Title]]></AdTitle>
                    <Creatives>
                        <Creative>
                            <CompanionAds/>
                        </Creative>
                        <Creative>
                            <Linear>
                                <TrackingEvents>
                                    <Tracking event="start"><![CDATA[http://localhost:3005/start]]></Tracking>
                                    <Tracking event="firstQuartile"><![CDATA[http://localhost:3005/first]]></Tracking>
                                    <Tracking event="midpoint"><![CDATA[http://localhost:3005/midpoint]]></Tracking>
                                    <Tracking event="thirdQuartile"><![CDATA[http://localhost:3005/third]]></Tracking>
                                    <Tracking event="complete"><![CDATA[http://localhost:3005/complete]]></Tracking>
                                    <Tracking event="stop"><![CDATA[http://localhost:3005/stop]]></Tracking>
                                </TrackingEvents>
                                <Duration><![CDATA[00:30]]></Duration>
                                <MediaFiles>
                                    <MediaFile delivery="progressive" type="video/mp4" width="320" height="240"
                                    ><![CDATA[http://localhost:9001/local-assets/testsrc.mp4]]></MediaFile>
                                </MediaFiles>
                                <VideoClicks>
                                    <ClickThrough id="scanscout"><![CDATA[{SURF_COMMERCE_URL}]]></ClickThrough>
                                </VideoClicks>
                            </Linear>
                            <Extensions>
                                <Extension>
                                    <Name>Wicked Sour Gummies</Name>
                                    <BrandName>Surfside</BrandName>
                                    <ProductType>Edible</ProductType>
                                </Extension>
                            </Extensions>
                        </Creative>
                    </Creatives>
                </InLine>
            </Ad>
        </VAST>`;
    }

    private async _makeNative(requestId: string, impid: string, catalog: boolean): Promise<IBidResponse> {
        this.logger.debug('MockBidderService._makeNative', impid);
        return {
            id: requestId,
            seatbid: [
                {
                    bid: [{
                        id: impid,
                        impid,
                        price: 3.50,
                        nurl: 'https://www.surfside.io/win-notice',
                        adm: encodeURIComponent(JSON.stringify(this._makeNativeResponse(catalog)))
                    }]
                }
            ]
        };
    }

    private _makeNativeResponse(catalog: boolean): INativeResponse {
        const fakeProduct = FAKE_PRODUCTS[Math.floor(Math.random() * FAKE_PRODUCTS.length)];
        const adm = this._makeImage(NATIVE_SIZE, NATIVE_SIZE, fakeProduct.name);
        const img = adm?.querySelector('img')?.src;
        return {
            native: {
                ver: '1.2',
                eventtrackers: [
                    {
                        event: EventTypes.IMP,
                        method: EventTrackingMethods.IMG,
                        url: "<img width=1 height=1 style='display: none' src='https://www.surfside.io/pixel'/>"
                    },
                    {
                        event: EventTypes.IMP,
                        method: EventTrackingMethods.JS,
                        url: 'http://localhost:9001/local-assets/win-script.js'
                    }
                ],
                assets: [
                    {
                        id: NativeAssetId.TITLE,
                        required: 1,
                        title: {
                            text: fakeProduct.name
                        }
                    },
                    {
                        id: NativeAssetId.IMAGE,
                        required: 1,
                        img: {
                            type: NativeImageTypes.MAIN,
                            url: img ?? ''
                        }
                    },
                    {
                        id: NativeAssetId.SPONSORED,
                        required: 1,
                        data: {
                            value: fakeProduct.brand
                        }
                    },
                    {
                        id: NativeAssetId.DESC,
                        required: 1,
                        data: {
                            value: fakeProduct.description
                        }
                    }, {
                        id: NativeAssetId.RATING,
                        required: 1,
                        data: {
                            value: '4.5'
                        }
                    }, {
                        id: NativeAssetId.PRICE,
                        required: 1,
                        data: {
                            value: '50.00'
                        }
                    }, {
                        id: NativeAssetId.SALEPRICE,
                        required: 1,
                        data: {
                            value: '35.00'
                        }
                    },
                    {
                        id: NativeAssetId.DISPLAYURL,
                        required: 1,
                        data: {
                            value: 'https://www.surfside.io'
                        }
                    }, {
                        id: NativeAssetId.CTATEXT,
                        required: 1,
                        data: {
                            value: 'Add to bag'
                        }
                    }, {
                        id: NativeAssetId.SURF_CATEGORY,
                        required: 1,
                        data: {
                            value: 'Software'
                        }
                    }, {
                        id: NativeAssetId.SURF_THC,
                        required: 1,
                        data: {
                            value: '20%'
                        }
                    }, {
                        id: NativeAssetId.SURF_CBD,
                        required: 1,
                        data: {
                            value: '5%'
                        }
                    }, {
                        id: NativeAssetId.SURF_STRAIN,
                        required: 1,
                        data: {
                            value: 'Hybrid'
                        }
                    },
                    { /* Optionally add a product ID to the native response if requested */
                        ...((catalog)
                            ? {
                                id: NativeAssetId.SURF_META_PRODUCT,
                                required: 1,
                                data: {
                                    value: '123456'
                                }
                            }
                            : {}
                        )
                    }
                ],
                link: { url: 'https://www.surfside.io' }
            }
        };
    }

    private async _makeBanner(requestId: string, impid: string, banner: IBanner): Promise<IBidResponse> {
        this.logger.debug('MockBidderService._makeBanner', impid, banner);
        return {
            id: requestId,
            seatbid: [
                {
                    bid: [{
                        id: impid,
                        impid,
                        price: 3.50,
                        adm: this._makeImage(
                            banner.w ?? FALLBACK_WIDTH,
                            banner.h ?? FALLBACK_HEIGHT
                        )?.outerHTML
                    }]
                }
            ]
        };
    }

    /**
     * Creates a simple image banner with the dimensions of the requested banner size. The image is a gradient with the
     * dimensions of the requested banner size, and contains the text of the banner size in the center. The image is
     * base64 encoded and returned as the adm field of the bid response.
     *
     * @param width The width of the requested banner
     * @param height The height of the requested banner
     * @returns The adm as an HTMLElement, with a clickthrough URL wrapped around it,
     *          or null if the image could not be created (e.g. canvas context error)
     */
    private _makeImage(width: number, height: number, message?: string): HTMLElement | null {
        this.logger.debug('MockBidderService._makeImage', width, height);
        const canv = document.createElement('canvas');
        const originalWidth = width;
        const originalHeight = height;
        if (width % 10 === width && height % 10 === height) {
            width *= 100;
            height *= 100;
        }
        canv.width = width;
        canv.height = height;

        const ctx = canv.getContext('2d');
        if (ctx === null) {
            this.logger.error('MockBidderService._makeImage could not get canvas context');
            return null;
        }
        const gradient = ctx.createLinearGradient(0, 0, canv.width, canv.height);

        const randomColor1: string = (SurfColors as any)[Object.keys(SurfColors)[
            Math.floor(Math.random() * Object.keys(SurfColors).length)
        ]];
        const randomColor2: string = (SurfColors as any)[Object.keys(SurfColors)[
            Math.floor(Math.random() * Object.keys(SurfColors).length)
        ]];

        gradient.addColorStop(0, randomColor1);
        gradient.addColorStop(1, randomColor2);

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canv.width, canv.height);

        ctx.font = '48px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 5;

        const text = message ?? `${originalWidth}x${originalHeight}`;
        ctx.strokeText(text, canv.width / 2, canv.height / 2);
        ctx.fillText(text, canv.width / 2, canv.height / 2);

        const img = document.createElement('img');
        img.src = canv.toDataURL();
        img.width = width;
        img.height = height;

        this.logger.debug('MockBidderService._makeImage returning', img);

        const anchor = document.createElement('a');
        const coinflip = Math.round(Math.random()) === 0;
        if (coinflip) {
            anchor.href = 'https://www.surfside.io';
        } else {
            anchor.href = '{SURF_COMMERCE_URL}';
        }
        anchor.appendChild(img);

        const data: IProductInformation = {
            name: 'Product Title',
            details: 'Product Description',
            price: '50.00',
            salePrice: '35.00',
            clickthroughUrl: 'https://www.surfside.io',
            cta: 'Add to bag',
            productType: 'Flower',
            thc: '20%',
            cbd: '5%',
            strain: 'Hybrid',
            image: img.src,
            brandName: 'Surfside',
            winUrl: 'https://www.surfside.io/win-notice',
            pixelUrl: 'https://www.surfside.io/pixel',
            ext: {}
        };

        const productInfo = document.createElement('div');
        productInfo.id = SURF_PRODUCT_INFO;
        productInfo.setAttribute('data-name', data.name ?? '');
        productInfo.setAttribute('data-details', data.details ?? '');
        productInfo.setAttribute('data-price', data.price ?? '');
        productInfo.setAttribute('data-saleprice', data.salePrice ?? '');
        productInfo.setAttribute('data-cta', data.cta ?? '');
        productInfo.setAttribute('data-producttype', data.productType ?? '');
        productInfo.setAttribute('data-thc', data.thc ?? '');
        productInfo.setAttribute('data-cbd', data.cbd ?? '');
        productInfo.setAttribute('data-strain', data.strain ?? '');
        productInfo.setAttribute('data-brandname', data.brandName ?? '');

        anchor.appendChild(productInfo);
        this.logger.debug('MockBidderService._makeImage returning', anchor);

        return anchor;
    }
}
