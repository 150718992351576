import type { ICookieService, ILoggerService } from 'services';

/**
 * Grabs the user's surfid cookie from the site
 */
export class SurfsideCookieService implements ICookieService {
    constructor(private readonly _logger: ILoggerService) {
        this._logger.debug('SurfsideCookieService.constructor');
    }

    getCookie(): string | null {
        this._logger.debug('SurfsideCookieService.getCookie');
        const cookieValue = document.cookie
            ?.split(';')
            ?.map(cookie => cookie.trim())
            ?.find(cookie => cookie.startsWith('surfid'))
            ?.split('=')[1]
            ?.match(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/)?.[0];
        return cookieValue ?? null;
    }
}
