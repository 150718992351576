import type { IGeoContext } from 'models';
import type { IGeoService, ILoggerService } from 'services';

/**
 * Calls the surfside geo service. A simple GET request returns the user's location based on their IP address.
 */
export class SurfsideGeoService implements IGeoService {
    constructor(private readonly _logger: ILoggerService) {
        this._logger.debug('SurfsideGeoService.constructor');
    }

    async getGeo(): Promise<IGeoContext | null> {
        this._logger.debug('SurfsideGeoService.getGeo');
        try {
            const response = await fetch('https://g.surfside.io/enrich');
            this._logger.debug('SurfsideGeoService.getGeo response', response);
            return await response.json() as unknown as IGeoContext;
        } catch (err) {
            this._logger.error('Failed to get geolocation information', err);
            return null;
        }
    }
}
