import {
    type ICompiledPublisherConfiguration,
    type ICompiledPublisherConfigurationDTO
} from 'models';
import { IConfigurationService, type ILoggerService } from 'services';

import clickThroughUrlTemplate from './mock-configuration-templates/ClickthroughURL.component.hbs';
import productCardTemplate from './mock-configuration-templates/ProductCard.component.hbs';
import productCardStylesheet from './mock-configuration-templates/ProductCardStylesheet.component.scss';
import carouselStylesheet from './mock-configuration-templates/CarouselStylesheet.component.scss';

const INDENT_LEVEL = 4;

/**
 * A mock configuration service that returns a hard-coded configuration. Enables geo and IP targeting by default.
 */
export class MockConfigurationService extends IConfigurationService {
    private _config: ICompiledPublisherConfiguration = {
        templates: {
            clickThroughUrlTemplate,
            productCardTemplate,
            productCardStylesheet,
            carouselStylesheet,
            javascriptCallbacks: {
                click: function click(e, product) {
                    window.postMessage({ event: 'addToCart', data: product.ext }, '*');
                }
            }
        },
        settings: {
            commerceMediaEnabled: true,
            ipTargetingEnabled: true,
            geoTargetingEnabled: true,
            reauctionEnabled: false,
            reauctionIntervalMs: 10000,
            productFeedEnabled: true
        }
    };

    constructor(logger: ILoggerService) {
        super(logger);
        this.logger.debug('MockConfigurationService.constructor');
        this.logger.info('MockConfigurationService config', JSON.stringify(this._config, null, INDENT_LEVEL));
    }

    async getConfiguration(): Promise<ICompiledPublisherConfiguration | null> {
        this.logger.debug('MockConfigurationService.getConfiguration');
        this.logger.debug('MockConfigurationService.getConfiguration returning', this._config);
        return this._config;
    }

    async setConfiguration(config: ICompiledPublisherConfigurationDTO): Promise<void> {
        this.logger.debug('MockConfigurationService.setConfiguration', config);
        this._config = await this.mapConfig(config);
    }
}
