export enum HttpStatus {
    /* Good status */
    OK = 200,
    NO_CONTENT = 204,

    /* Client error status */
    BAD_REQUEST = 400,
    NOT_FOUND = 404,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
}

export enum HttpStatusClass {
    SUCCESS = 2,
    CLIENT_ERROR = 4,
    SERVER_ERROR = 5
}

export enum SurfColors {
    /* Accents */
    INDIGO = '#331c7a',
    ULTRAMARINE = '#1f69ff',
    AQUAMARINE = '#4ceaaa',
    ELECTRIC_LIME = '#c5f700',
    CRAYOLA = '#f7a800',
    ORANGE = '#f66d18',
    RED = '#e72024',

    LAVENDER = '#c0abff',
    ULTRA_RED = '#f97585',
    SOFT_GOLD = '#f4bd5d',
    MAGIC_MINT = '#baf7db',
    SKY_BLUE = '#7eceeb',
    CARIBBEAN_GREN = '#0dcc91',
    LAVENDER_GRAY = '#d3d3e0'
}

export enum IPServices {
    IP2LOCATION = 1,
    NEUSTAR = 2,
    MAXMIND = 3,
    NETACUITY = 4
};

export enum LocationType {
    GPS = 1,
    IP = 2,
    USER = 3
}

export enum Environment {
    PROD = 'PROD',
    STAGING = 'STAGING',
    LOCAL = 'LOCAL',
    MOCK = 'MOCK'
};
