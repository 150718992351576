import { ISurfsideComponent } from 'components';

import template from './SurfCarousel.component.hbs';
import style from './SurfCarousel.component.scss';
import { RenderAndBind } from 'utils';
import type { IProductInformation } from 'models';
import { type ILoggerService, RecommenderType } from 'services';

const DEFAULT_CARD_MARGIN: number = 15;
enum NextType {
    PRODUCT,
    PAGE
};

export enum CarouselStrategy {
    SPONSORED = 'sponsored',
    HYBRID = 'hybrid',
    RECOMMENDED = 'recommended'
}

export class SurfCarousel extends ISurfsideComponent {
    private _startX?: number;
    private _endX?: number;
    private _carousel: HTMLElement | null = null;
    private _items: HTMLElement | null = null;
    private _leftArrow: HTMLElement | null = null;
    private _rightArrow: HTMLElement | null = null;
    private _itemOffset: number = 0;
    private _maxItems: number | null = null;
    private _maxPage: number | null = null;
    private _nextType: NextType = NextType.PRODUCT;
    private _catalog: boolean = false;
    private _margin: number = DEFAULT_CARD_MARGIN;
    private _strategy: CarouselStrategy = CarouselStrategy.HYBRID;
    private _recommend: RecommenderType = RecommenderType.TOP_ALL;

    private readonly _breakpoints: SurfBreakpoint[] = [];
    private _activeBreakpoint?: number;

    private readonly _productSemaphore: ProductSemaphore =
        new ProductSemaphore(0, this._updateBrandInformation.bind(this), this.logger);

    private readonly _failureSemaphore: ProductSemaphore =
        new ProductSemaphore(0, this.remove.bind(this), this.logger);

    public async init(): Promise<void> {
        await super.init();

        this.addEventListener('productCardRendered', (e) => {
            const event = e as CustomEvent<IProductInformation>;
            this.logger.debug('Product card rendered', event.detail);
            this._productSemaphore.increment(event.detail);
        });

        this.addEventListener('productCardFailed', () => {
            this._failureSemaphore.increment(null);
        });

        this._maxItems = this.numberOrNull(this.getAttribute('max-items'));
        this._maxPage = this.numberOrNull(this.getAttribute('max-page'));
        this._catalog = this.getAttribute('catalog')?.toLowerCase() === 'true';
        this._margin = this.numberOrNull(this.getAttribute('margin')) ?? DEFAULT_CARD_MARGIN;
        this._strategy = this.getEnumOrNull('strategy', CarouselStrategy) ?? CarouselStrategy.HYBRID;
        this._recommend = this.getEnumOrNull('recommend', RecommenderType) ?? RecommenderType.TOP_ALL;

        // Can change depending on screen size, but we initialize here first
        const nextTypeAttribute = this.getAttribute('next-type')?.toLowerCase();
        if (nextTypeAttribute === 'page') {
            this._nextType = NextType.PAGE;
        }
    }

    public async connectedCallback(): Promise<void> {
        await this.init();
        /* Breakpoint parsing goes in connectedCallback because the element's
         * children are not available in the constructor due to initialization
         * order (carousel's constructor calls before breakpoint's consturctor) */
        const breakpoints = [...this.querySelectorAll('surf-breakpoint')]
            .map((e: Element) => e as SurfBreakpoint);
        this._breakpoints.push(...breakpoints);
        const activeBreakpoint = this._breakpoints.find((breakpoint) => {
            const query = this._getMediaQuery(breakpoint);
            if (query === '') {
                return true;
            }
            const matches = window.matchMedia(query).matches;
            return matches;
        });

        let initialSet: number | null = this._maxPage;
        if (activeBreakpoint !== undefined) {
            const cardsAttr = activeBreakpoint.getAttribute('cards');
            if (cardsAttr === null) {
                throw new Error('Surfside Carousel Breakpoints require a cards attribute');
            }
            initialSet = parseInt(cardsAttr);

            if (Number.isNaN(initialSet)) {
                throw new Error('Surfside Carousel Breakpoints cards attribute must be a number');
            }
        }

        if (initialSet === null) {
            throw new Error(
                'Surf Carousel: initial set of cards is null. Add a <surf-breakpoint> or set the max-items attribute'
            );
        }
        this._activeBreakpoint = initialSet;

        this.appendStylesheet(style);
        this.appendStylesheet((await this.configuration)?.templates.carouselStylesheet ?? '');
        this.mergeChildren(RenderAndBind(template, {
            touchStart: this._touchStart.bind(this),
            touchMove: this._touchMove.bind(this),
            touchEnd: this._touchEnd.bind(this),
            nextProduct: this._nextProduct.bind(this),
            previousProduct: this._previousProduct.bind(this)
        }));
        this._carousel = this.shadow.querySelector('.carousel');
        this._items = this.shadow.querySelector('.items');
        this._leftArrow = this.shadow.querySelector('.arrow.left');
        this._rightArrow = this.shadow.querySelector('.arrow.right');
        if (this._leftArrow !== null) {
            this._leftArrow.style.visibility = 'hidden';
        }
        if (this._carousel !== null) {
            this._productSemaphore.reset(this._activeBreakpoint);
            this._failureSemaphore.reset(this._activeBreakpoint);
            this._addProductCard(this._activeBreakpoint);
        } else {
            throw new Error('Surf Carousel: No carousel element found! Malformed carousel template');
        }
    }

    private _touchStart(e: Event): void {
        this._startX = (e as TouchEvent).touches[0].clientX;
        this.logger.debug(e);
        if (this._items !== null && e.target !== this._leftArrow && e.target !== this._rightArrow) {
            this._items.style.transition = 'none';
        }
    }

    private _touchMove(e: Event): void {
        if (this._carousel === null) {
            this.logger.debug('No carousel element found');
            return;
        }
        if (this._items === null) {
            this.logger.debug('No items element found');
            return;
        }

        if (this._startX === undefined) {
            this.logger.debug('No touchstart event found');
            return;
        }
        const x = (e as TouchEvent).touches[0].clientX;
        this._endX = x;

        const maxSwipe = this._items.children[0].clientWidth; // assume all children are the same width
        const displacement = this._smoothStop2(Math.abs(x - this._startX), maxSwipe);
        const tmpOffset = displacement * maxSwipe;
        const currentOffset = this._getOffset(this._itemOffset) ?? 0;
        if (x < this._startX) {
            this._items.style.marginLeft = `-${currentOffset + tmpOffset}px`;
        } else if (x > this._startX) {
            this._items.style.marginLeft = `-${currentOffset - tmpOffset}px`;
        }
    }

    private _getMediaQuery(breakpoint: SurfBreakpoint): string {
        const minAttr = breakpoint.getAttribute('min');
        let min = minAttr !== null
            ? parseInt(minAttr)
            : undefined;
        if (Number.isNaN(min)) {
            min = undefined;
        }

        const maxAttr = breakpoint.getAttribute('max');
        let max = maxAttr !== null
            ? parseInt(maxAttr)
            : undefined;
        if (Number.isNaN(max)) {
            max = undefined;
        }

        const minQuery = min !== undefined
            ? `(min-width: ${min}px)`
            : undefined;

        const maxQuery = max !== undefined
            ? `(max-width: ${max}px)`
            : undefined;

        return [minQuery, maxQuery].filter(query => query !== undefined).join(' and ');
    }

    private _touchEnd(e: Event): void {
        this.logger.debug(e);
        if (this._startX === undefined || this._endX === undefined) {
            this.logger.debug('No touchstart event found');
            return;
        }
        if (this._carousel === null) {
            this.logger.debug('No carousel element found');
            return;
        }
        if (this._items === null) {
            this.logger.debug('No items element found');
            return;
        }
        this.logger.debug(e);
        this._items.style.transition = 'margin-left 0.75s';

        if (this._endX < this._startX && this._itemOffset <= this._items.children.length - 1) {
            this._nextProduct();
        } else if (this._endX > this._startX && this._itemOffset > 0) {
            this._previousProduct();
        }
        this._startX = undefined;
        this._endX = undefined;
    }

    private _getOffset(index: number): number | undefined {
        if (this._items === null) {
            return undefined;
        }
        if (index > this._items.children.length || index < 0) {
            return undefined;
        }
        let width = 0;
        for (let i = 0; i < this._itemOffset; i++) {
            width += (this._items.children[i] as HTMLElement).offsetWidth;
        }
        return width;
    }

    private _smoothStop2(i: number, max: number): number {
        const x = Math.min(max, i) / max;
        return 1 - (1 - x) * (1 - x);
    }

    private _nextProduct(): void {
        if (this._items === null || this._carousel === null) {
            this.logger.error("Cannot iterate page; no 'items' found");
            return;
        }

        const maxVisibleItems = this._activeBreakpoint ?? 0;
        this.logger.debug(`Max visible items: ${maxVisibleItems}`);
        const numProducts = this._nextType === NextType.PRODUCT
            ? 1
            : maxVisibleItems;

        if (this._maxItems !== null) {
            this._itemOffset = Math.min(this._maxItems - maxVisibleItems, this._itemOffset + numProducts);
        } else {
            this._itemOffset += numProducts;
        }

        if (this._itemOffset > 0 && this._leftArrow !== null) {
            this._leftArrow.style.visibility = 'visible';
        }

        const width = this._getOffset(this._itemOffset) ?? 0;
        this._items.style.marginLeft = `-${width}px`;

        const totalVisibleItems = this._items.children.length - this._itemOffset;

        if (totalVisibleItems < maxVisibleItems) {
            let toAdd = maxVisibleItems - totalVisibleItems;
            if (this._maxItems !== null) {
                toAdd = Math.max(0, Math.min(this._maxItems - this._items.children.length, toAdd));
                this.logger.debug(`Max items set to ${this._maxItems}; adding ${toAdd} product card(s)`);
            }
            this.logger.debug(`Added ${toAdd} product card(s)`);
            this._addProductCard(toAdd);
            this._productSemaphore.reset(toAdd);
            this._failureSemaphore.reset(toAdd);
        }

        if (this._maxItems !== null && this._itemOffset + maxVisibleItems >= this._maxItems) {
            this._rightArrow?.style.setProperty('visibility', 'hidden');
        }
    }

    private _previousProduct(): void {
        if (this._items === null || this._carousel === null) {
            this.logger.error("Cannot iterate page; no 'items' or carousel found");
            return;
        }

        const maxVisibleItems = this._activeBreakpoint ?? 0;
        const numProducts = this._nextType === NextType.PRODUCT
            ? 1
            : maxVisibleItems;

        if (this._itemOffset > 0) {
            this._itemOffset = Math.max(0, this._itemOffset - numProducts);
        }

        if (this._itemOffset <= 0 && this._leftArrow !== null) {
            this._leftArrow.style.visibility = 'hidden';
        }

        const width = this._getOffset(this._itemOffset) ?? 0;
        this._items.style.marginLeft = `-${width}px`;

        this._rightArrow?.style.setProperty('visibility', 'visible');
    }

    private _updateBrandInformation(products: IProductInformation[]): void {
        this.logger.debug('Updating brand information');
        const brands = new Set(products.map((product) => product.brandName));
        this.logger.debug('Brands:', brands);
        this.logger.debug(this);
        if (brands.size === 1) {
            this.logger.debug('All products are from the same brand');
            const childShadows = [...this.shadowRoot?.querySelectorAll('surf-product-card') ?? []]
                .map((e) => e.shadowRoot);
            this.logger.debug('Child shadows:', childShadows);
            childShadows.forEach((e) => {
                this.logger.debug('Shadow: ', e);
                const sponsored = e?.querySelector('.sponsored-card');
                if (sponsored === null) {
                    this.logger.error('Cannot find sponsored card');
                    return;
                }
                this.logger.debug('Hiding sponsored card', sponsored);
                (sponsored as HTMLElement).style.visibility = 'hidden';
            });
            const sponsoredBy = this.shadowRoot?.querySelector('.sponsored-carousel') as HTMLElement | null;
            if (sponsoredBy === null) {
                this.logger.error('Cannot find sponsored by element');
                return;
            }
            const brand = [...brands][0];
            this.logger.debug('Setting sponsored by:', brand);
            sponsoredBy.innerHTML = `${brand}`;
        }
    }

    private _addProductCard(numCards: number): void {
        if (this._items === null) {
            this.logger.error('Cannot add new product card; missing items element');
            return;
        }
        this.logger.debug('Adding product card', numCards);

        for (let i = 0; i < numCards; i++) {
            const TO_PERCENT = 100;
            const newCard = document.createElement('div');
            newCard.className = 'product-card';
            newCard.style.flex = `0 0 ${((1 / (this._activeBreakpoint ?? 1)) * TO_PERCENT)}%`;
            newCard.style.padding = `10px ${this._margin}px`;
            newCard.style.boxSizing = 'border-box';
            newCard.style.overflow = 'hidden';
            newCard.style.verticalAlign = 'top';

            // generate random hex number:
            const maxRandom = 16777215;
            const base = 16;
            const randomNum = Math.floor(Math.random() * maxRandom).toString(base);
            newCard.innerHTML = `
                <Surf-Product-Card
                    account-id="${this.accountId}"
                    site-id="${this.siteId}"
                    placement-id="${this.placementId}/${(this._nextType === NextType.PAGE ? '' : String(randomNum))}"
                    channel-id="${this.channelId}"
                    location-id="${this.locationId}"
                    catalog="${this._catalog}"
                    strategy="${this._strategy}"
                    recommend="${this._recommend}"
                >
                </SurfProductCard>`;
            this._items.appendChild(newCard);
        }
    }
}

export class SurfBreakpoint extends HTMLElement {
    private _min?: number;
    private _max?: number;
    private _cards: number = 0;

    get min(): number | undefined {
        return this._min;
    }

    get max(): number | undefined {
        return this._max;
    }

    get cards(): number {
        return this._cards;
    }

    public connectedCallback(): void {
        const minAttr = this.getAttribute('min');
        let min = minAttr !== null
            ? parseInt(minAttr)
            : undefined;

        if (Number.isNaN(min)) {
            min = undefined;
        }
        this._min = min;

        const maxAttr = this.getAttribute('max');
        let max = maxAttr !== null
            ? parseInt(maxAttr)
            : undefined;
        if (Number.isNaN(max)) {
            max = undefined;
        }
        this._max = max;

        if (this._max === undefined && this._min === undefined) {
            throw new Error('Surfside Carousel Breakpoints require at least a min or max attribute');
        }

        const cardsAttr = this.getAttribute('cards');
        if (cardsAttr === null) {
            throw new Error('Surfside Carousel Breakpoints require a cards attribute');
        }
        const cards = parseInt(cardsAttr);
        if (Number.isNaN(cards)) {
            throw new Error('Surfside Carousel Breakpoints cards attribute must be a number');
        }
        this._cards = cards;
    }

    public getMediaQuery(): string {
        const minQuery = this._min !== undefined
            ? `(min-width: ${this._min}px)`
            : undefined;
        const maxQuery = this._max !== undefined
            ? `(max-width: ${this._max}px)`
            : undefined;
        return [minQuery, maxQuery]
            .filter(query => query !== undefined)
            .join(' and ');
    }
}

class ProductSemaphore {
    private _count: number = 0;
    private _requested: number = 0;
    private readonly _callback: (products: IProductInformation[]) => void;
    private readonly _products: IProductInformation[] = [];
    private readonly _logger: ILoggerService;

    constructor(requested: number, callback: (products: IProductInformation[]) => void, logger: ILoggerService) {
        this._requested = requested;
        this._callback = callback;
        this._logger = logger;
    }

    public reset(requested: number = this._requested): void {
        this._count = 0;
        this._requested = requested;
        this._products.length = 0;
        this._logger.debug(`ProductSemaphore: Reset to ${this._count}/${this._requested}`);
    }

    /* Null product indicates a failure to load the product card */
    public increment(product: IProductInformation | null): void {
        this._count++;
        this._logger.debug(`ProductSemaphore: ${this._count}/${this._requested}`);
        if (product !== null) {
            this._products.push(product);
        }
        if (this._count === this._requested) {
            this._logger.debug('ProductSemaphore: callback');
            this._callback(this._products);
            this.reset();
        }
    }
}
