const DEFAULT_MAX_SLUG: number = 5;

/**
 * Converts the passed string to a slug with the specified options. By default, the slug is hyphenated and only
 * contains alphanumeric characters. You can pass a different hyphenation regex and a different drop regex to
 * customize the slug. For example, {{slug "My Product Name"}} will return "my-product-name".
 *
 * To change which characters are converted to hyphens, you can pass a regular expression to the hyphenate option.
 * For example, {{slug "My Product's Name" hyphenate="[\s']"}} will return "my-product-s-name".
 * The default hyphenate regex is /[\s_\-,]/ig.
 *
 * To remove characters instead of hyphenate them, you can pass a regular expression to the drop option.
 * For example, {{slug "My Product's Name" drop="[^A-Za-z0-9\s']"}} will return "my-product's-name".
 * The default drop regex is /[^A-Za-z0-9\-]/ig.
 *
 * To limit the max number of hyphenated slug components returned, you can pass a length option.
 * For example, {{slug "My Product's Name" length=2}} will return "my-product".
 * The default length is 5.
 *
 * @param name The string to be slugified
 * @param options The options object. Can take "hypenate", "drop", and "length" options.
 * @returns The slugified string
 */
export function slug(
    name: string,
    options: {
        hash: {
            hyphenate: string | undefined;
            drop: string | undefined;
            length: number | undefined;
        };
    }
): string {
    if (name === undefined || name === null || typeof (name) !== 'string') {
        return '';
    }

    const hyphenate: RegExp = options.hash.hyphenate !== undefined
        ? new RegExp(options.hash.hyphenate, 'ig')
        : /[\s_\-,]/ig;

    const drop: RegExp = options.hash.drop !== undefined
        ? new RegExp(options.hash.drop, 'ig')
        : /[^A-Za-z0-9-]/ig;

    const maxSlug: number = options.hash.length ?? DEFAULT_MAX_SLUG;

    return name
        .replace(hyphenate, '-')
        .replace(drop, '')
        .toLowerCase()
        .split('-')
        .slice(0, maxSlug)
        .join('-');
}

/**
 * Converts the passed string to lowercase. For example, {{lower "My Product Name"}} will return "my product name".
 *
 * @param name The string to be converted to lowercase
 * @returns A lowercase version of the passed string
 */
export function lower(name: string): string {
    if (name === undefined || name === null || typeof (name) !== 'string') {
        return '';
    }
    return name.toLowerCase();
}

/**
 * Returns the path component of the URL at the specified position, 1-indexed
 * For example, if the URL is "https://example.com/foo/bar", {{path 1}} will return "foo".
 *
 * @param position The position of the path component to return, 1-indexed
 * @returns The path component at the specified position
 */
export function path(position: number): string {
    if (position === 0 || position === undefined || position === null) {
        return '';
    }
    const paths = window.location.pathname.split('/');
    if (paths.length <= position) {
        return '';
    }
    return paths[position];
}

/**
 * Returns the value of the specified query parameter from the URL, specified by key.
 * For example, if the URL is "https://example.com?foo=bar", {{search "foo"}} will return "bar".
 *
 * @param name The name of the query parameter to return
 * @returns The value of the query parameter, or an empty string if the parameter is not found
 */
export function search(name: string): string {
    if (name?.length === undefined || name.length === 0) {
        return '';
    }
    const search = window.location.search;
    if (search.length === 0) {
        return '';
    }
    const params = new URLSearchParams(search);
    return params.get(name) ?? '';
}

/**
 * Returns the first truthy argument, or an empty string if all arguments are falsy. Returns false for empty strings
 * For example, {{coalesce false (eq "b" "d") c}} will return "c", because false is falsy, "b" is not equal to "d",
 * and c is truthy.
 *
 * @returns The first truthy argument, or an empty string if all arguments are falsy
 */
export function coalesce(): string {
    for (let i = 0; i < arguments.length - 1; i++) {
        const arg = arguments[i];
        if (arg !== null && arg !== undefined && arg !== '' && arg !== false) {
            return arg;
        }
    }
    return '';
}

/**
 * Joins the passed arguments with the specified separator.
 * For example, {{join "foo" "bar" "baz"}} will return "foo/bar/baz"..
 *
 * There is an optional "separator" argument that can be passed to change the separator. For example,
 * {{join "foo" "bar" "baz" separator=","}} will return "foo,bar,baz".
 *
 * @returns The joined string
 */
export function join(): string {
    const args = [...arguments].slice(0, arguments.length - 1);
    const options = arguments[arguments.length - 1]; /* options is always the last argument */
    const separator = options.hash.separator as string ?? '/';
    return args
        .filter(arg => arg !== undefined && arg !== null && arg !== '') /* filter out nulls and empties */
        .join(separator);
}

/**
 * Encodes the passed string for use in a URI component.
 *
 * @param component The string to encode
 * @returns The encoded string, or an empty string if the component is falsy
 */
export function uri(component: string): string {
    if (component?.length === undefined || component.length === 0) {
        return '';
    }
    const encoded = encodeURIComponent(component);
    return encoded.replaceAll("'", '%27');
}

/**
 * Returns true if the two passed arguments are equal (using javascript ===), false otherwise.
 *
 * @param a The first argument
 * @param b The second argument
 * @returns True if the two arguments are equal, false otherwise
 */
export function eq(a: any, b: any): boolean {
    return a === b;
}

/**
 * Returns true if either of the passed arguments are truthy, false otherwise.
 *
 * @param a The first argument
 * @param b The second argument
 * @returns True if either of the arguments are truthy, false otherwise
 */
export function or(a: any, b: any): boolean {
    return Boolean(a) || Boolean(b);
}

/**
 * Returns true if both of the passed arguments are truthy, false otherwise.
 *
 * @param a The first argument
 * @param b The second argument
 * @returns True if both of the arguments are truthy, false otherwise
 */
export function and(a: any, b: any): boolean {
    return Boolean(a) && Boolean(b);
}

/**
 * Returns true if the first argument is in the second argument (an array). Otherwise, returns false.
 * If the second argument is not an array, or the array is empty, this will return false.
 *
 * The name of the helper is "in" but the name of the function is "isIn" because "in" is a reserved word in javascript.
 *
 * @param a The value to check
 * @param b The array to check against
 * @returns True if the value is in the array, false otherwise
 */
export function isIn(a: any, b: any[]): boolean {
    if (b?.length === undefined || b.length === 0) {
        return false;
    }
    return b.includes(a);
}

/**
 * Returns an array of the passed arguments. Useful with the "in" helper
 *
 * @returns An array of the passed arguments
 */
export function array(): any[] {
    return Array.from(arguments).slice(0, -1);
}
