import type { ICreativeComponent } from 'components';
import type { IBidRequest } from 'models';
import type { IRequestMiddleware } from './IRequestMiddleware';

interface SurfsideBidRequestContext {
    accountId: string;
    channelId: string;
    siteId: string;
    placementId: string;
    locationId: string | null;
    storeId: string | null;
    zone: string | null;
    keywords: string[] | null;
};

export class SurfContextMiddleware implements IRequestMiddleware {
    async interceptRequest(request: IBidRequest, component: ICreativeComponent): Promise<IBidRequest> {
        const ext: SurfsideBidRequestContext = {
            accountId: component.accountId,
            channelId: component.channelId,
            siteId: component.siteId,
            placementId: component.siteId,
            locationId: component.locationId,
            storeId: component.locationId,
            zone: component.zone,
            keywords: component.keywords
        };

        /* Set it at the request level */
        request.ext = ext;

        /* Set it for each impression as well */
        for (const imp of request.imp) {
            imp.ext = ext;
            imp.tagid = ext.placementId;
        }

        return request;
    }
}
