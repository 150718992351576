import type { IGeoContext } from 'models';
import type { IGeoService, ILoggerService } from 'services';

/**
 * A mock geo service that returns a hard-coded geo context for the greatest city in America. Returns 127.0.0.1 for the
 * IP address.
 */
export class MockGeoService implements IGeoService {
    constructor(private readonly _logger: ILoggerService) {
        this._logger.debug('MockGeoService.constructor');
    }

    async getGeo(): Promise<IGeoContext | null> {
        this._logger.debug('MockGeoService.getGeo');
        const geo = {
            country: 'US',
            region: 'VA',
            city: 'Richmond',
            zip: '23220',
            coords: {
                latitude: 37.548332,
                longitude: 77.453262,
                accuracy: 20
            },
            utcoffset: -4,
            ip: '127.0.0.1'
        };
        this._logger.debug('MockGeoService.getGeo returning', geo);
        return geo;
    }
}
