import type { ICompiledPublisherConfigurationDTO } from 'models/retail';

export enum InteractiveMessageEvents {
    READY = 'Ready',
    SET_CONFIG = 'SetConfig'
};

export interface IInteractiveMessage {
    namespace: 'surfside';
    event: any;
    data: any;
}

export interface IInteractiveMessageImpl<T, U> extends IInteractiveMessage {
    event: T;
    data: U;
};

export interface ISetConfigMessage extends
    IInteractiveMessageImpl<InteractiveMessageEvents.SET_CONFIG, ICompiledPublisherConfigurationDTO> {
};

export interface IReadyMessage extends IInteractiveMessageImpl<InteractiveMessageEvents.READY, never> {
};
