import {
    type IRequestMiddleware,
    type IResponseMiddleware,

    BasicRequestMiddleware,
    GeoMiddleware,
    IdentityMiddleware,
    PriceNurlMiddleware,
    SnowplowRequestMiddleware,
    SnowplowResponseMiddleware
} from 'middleware';
import type { IBid, IBidRequest, IBidResponse } from 'models';
import { ISurfsideComponent } from './ISurfsideComponent';
import { SurfContextMiddleware } from 'middleware/SurfContextMiddleware';

const MIN_AUCTION_INTERVAL = 10000;

/**
 * The base class for all creative components. This class is an abstract class that defines the attributes which are
 * common to all creative components. It also defines the interface that all creative components must implement.
 *
 * The basic interface for all creative components is the makeRequest method and the render method (see notes below)
 */
export abstract class ICreativeComponent extends ISurfsideComponent {
    /** Request Middleware alters the bid request after the component constructs it and before it is sent to the bidder.
     * This is useful for enriching the request with data about the user or the page.
    */
    private readonly _requestMiddleware: IRequestMiddleware[] = [];

    /** Response Middleware alters the bid response after the bidder sends it back and before the creative renders it.
     * This is useful for enriching the response with data about the user or the page.
    */
    private readonly _responseMiddleware: IResponseMiddleware[] = [];

    constructor() {
        super();
        this.logger.debug('ICreativeComponent.constructor initializing');

        this._requestMiddleware.push(new BasicRequestMiddleware());
        this._requestMiddleware.push(new GeoMiddleware());
        this._requestMiddleware.push(new SurfContextMiddleware());
        this._requestMiddleware.push(new IdentityMiddleware());
        this._requestMiddleware.push(new SnowplowRequestMiddleware());
        this.logger.debug('ICreativeComponent.constructor loaded request middleware', this._requestMiddleware);
        this._responseMiddleware.push(new PriceNurlMiddleware());
        this._responseMiddleware.push(new SnowplowResponseMiddleware());
        this.logger.debug('ICreativeComponent.constructor loaded response middleware', this._responseMiddleware);

        this.logger.debug('ICreativeComponent.constructor initialized');
    }

    /* ==== Interface ==== */

    /**
     * Returns a BidRequest object that can be sent to the bidder service. A null bid request means to go straight
     * to the fallback logic.
     */
    public abstract makeRequest(): Promise<(IBidRequest) | null>;

    protected reauction(): void {
        this.logger.debug('ICreativeComponent.reauction reauctioning');
        const replica = this.outerHTML;
        this.logger.debug('ICreativeComponent.reauction reauctioning replica', replica);
        this.outerHTML = replica;
    }
    /**
     * Takes the bidresponse from the bidder and renders the creative.
     * @param bidResponse The BidResponse object from the bidder.
     */
    public abstract render(bidResponse: IBidResponse, bid: IBid): Promise<void>;

    /**
     * Callback for when then component fails early in the process (i.e: bad attributes, no bid response)
     */
    public fail(): void {
        this.logger.debug('ICreativeComponent.fail');
    }

    public async fallback(): Promise<void> {
        this.logger.debug('ICreativeComponent.fallback Default fallback logic does nothing');
    }

    /* The entrypoint of all WebComponents. All errors need to be caught here so we can log them and kill the process.
     * returning void from here will end the entire process */
    public async connectedCallback(): Promise<void> {
        await this.init();
        this.logger.debug('ICreativeComponent.connectedCallback', this);

        /* Make the bid request. This can optionally throw an error (e.g: a required service returned some null data) */
        let request: IBidRequest | null;
        try {
            request = await this.makeRequest();
        } catch (err) {
            this.logger.error('ICreativeComponent.connectedCallback error', err);
            this.fail();
            return;
        }
        if (request === null) {
            this.logger.debug('ICreativeComponent.connectedCallback no bid request');
            await this.fallback();
            return;
        }
        this.logger.debug('ICreativeComponent.connectedCallback request', request);

        /* Go through all the middleware and let them alter the request. These can optionally throw an error
         * (e.g: a required service returned some null data) */
        try {
            for (const middleware of this._requestMiddleware) {
                request = await middleware.interceptRequest(request, this);
                this.logger.debug('ICreativeComponent.connectedCallback request after middleware', request);
            }
        } catch (err) {
            this.logger.error('ICreativeComponent.connectedCallback middleware error', err);
            this.fail();
            return;
        }

        /* Send the bid request. This can optionally throw an error (e.g: the bidder returned NO CONTENT, so the service
         * threw a BidderError */
        try {
            this.logger.debug('ICreativeComponent.connectedCallback sending bid request', request);
            const start = performance.now();
            let [response, bid] = await globalThis.surfside.bidder.bid(request);
            const end = performance.now();
            this.logger.info('Performance: Bidder response time', end - start, 'ms');
            for (const middleware of this._responseMiddleware) {
                response = await middleware.interceptResponse(response, this);
                this.logger.debug('ICreativeComponent.connectedCallback response after middleware', response);
            }
            this.logger.debug('ICreativeComponent.connectedCallback received bid response', response);

            /* Finally render the bid response. This can also throw an error */
            await this.render(response, bid);
            this.logger.debug('ICreativeComponent.connectedCallback rendered creative');
            const configuration = await this.configuration;
            const reauctionEnabled = configuration?.settings.reauctionEnabled;
            const reauctionInterval = configuration?.settings.reauctionIntervalMs;
            if (
                reauctionEnabled === true &&
                reauctionInterval !== undefined &&
                reauctionInterval >= MIN_AUCTION_INTERVAL
            ) {
                this.logger.debug('ICreativeComponent.connectedCallback reauctioning in', reauctionInterval, 'ms');
                setTimeout(this.reauction.bind(this), reauctionInterval);
            }
        } catch (err: any) {
            if (err instanceof Error) {
                this.logger.error('ICreativeComponent.connectedCallback error', err.message);
                this.fail();
            } else {
                // assume it's a Bidresponse based on type requirements in IDebouncedMulticastRequester
                await this.fallback();
            }
        }
    }
}
