/* eslint-disable @typescript-eslint/naming-convention */
import type { IProductInformation } from 'models';
import type { ILoggerService, IProductInformationService } from 'services';

export class MockProductInformationService implements IProductInformationService {
    constructor(private readonly _logger: ILoggerService) {}

    async getProductByNativeId(): Promise<IProductInformation> {
        this._logger.debug('MockProductInformationService.getProduct');
        return {
            image: 'https://cdn.surfside.io/assets/images/not-a-real-ad.png',
            price: '3.50',
            salePrice: '2.50',
            thc: '20%',
            name: 'Native Title - Surfside [10pk]',
            brandName: 'Surfside',
            productType: 'Edibles',
            details: 'This is a mock product. It is not real. Do not buy it. It is not for sale. ' +
                    'Get off the internet and go outside. Seriously. Go outside. Now. ' +
                    'Oh, you\'re still here? Fine. I guess I\'ll keep typing. I just need to fill some ' +
                    'space with text so that the banner looks like a real ad.',
            cta: 'Learn More',
            clickthroughUrl: 'https://www.surfside.io',
            winUrl: 'https://www.surfside.io',
            pixelUrl: 'https://www.surfside.io',
            strain: 'Indica',
            cbd: '0.5%',
            ext: {
                account_id: 123,
                source_id: 456,
                location_id: 789,
                item_id: '0b229d6b-8e15-4f8f-a67f-fe603935d1d4',
                name: 'Example Product',
                list: 'Main Catalog',
                brand: 'BrandName',
                category: 'Electronics',
                variant: 'Model X',
                price: 99.99,
                quantity: 50,
                coupon: 'DISCOUNT10',
                position: 'Top Seller',
                currency: 'USD',
                product_type: 'Gadget',
                product_category: 'Home Electronics',
                color: 'Black',
                size: 'Medium',
                material: 'Plastic',
                pattern: 'Solid',
                condition: 'New',
                cost_of_goods_sold: 50,
                shipping: 5.99,
                shipping_weight: 1.5,
                item_group_id: 'Group123',
                gender: 'Unisex',
                age_group: 'Adult',
                adult: true,
                restricted: false,
                country: 'USA',
                state: 'CA',
                zip: '90210',
                description: 'High-quality gadget.',
                link: 'http://example.com',
                image_link: 'http://example.com/img.jpg',
                additional_image_link: 'http://example.com/extra_img.jpg',
                model_3d_link: 'http://example.com/model_3d',
                lifestyle_image_link: 'http://example.com/lifestyle_img.jpg',
                availability: 'In Stock',
                availability_date: '2024-06-01',
                expiration_date: '2024-12-31',
                sale_price: 79.99,
                sale_price_effective_date: '2024-05-01',
                mpn: 'MPN123',
                gtin: '123456789012'
            }
        };
    }

    async getProductById(): Promise<IProductInformation> {
        return {
            image: 'https://cdn.surfside.io/assets/images/not-a-real-ad2.png',
            price: '10.50',
            salePrice: null,
            thc: '35%',
            name: 'Purple Rain 2g',
            brandName: 'Sidesurf',
            productType: 'Flower',
            details: 'This is a recommended product. It has been selected for you based on the top purchased ' +
                     'products at this location',
            cta: 'Learn More',
            clickthroughUrl: 'http://surfside.io/commerce-media',
            winUrl: 'https://www.surfside.io',
            pixelUrl: 'https://www.surfside.io',
            strain: 'Hybrid',
            cbd: '0.5%',
            ext: {
                account_id: 123,
                source_id: 456,
                location_id: 789,
                item_id: '0b229d6b-8e15-4f8f-a67f-fe603935d1d4',
                name: 'Example Product',
                list: 'Main Catalog',
                brand: 'BrandName',
                category: 'Electronics',
                variant: 'Model X',
                price: 99.99,
                quantity: 50,
                coupon: 'DISCOUNT10',
                position: 'Top Seller',
                currency: 'USD',
                product_type: 'Gadget',
                product_category: 'Home Electronics',
                color: 'Black',
                size: 'Medium',
                material: 'Plastic',
                pattern: 'Solid',
                condition: 'New',
                cost_of_goods_sold: 50,
                shipping: 5.99,
                shipping_weight: 1.5,
                item_group_id: 'Group123',
                gender: 'Unisex',
                age_group: 'Adult',
                adult: true,
                restricted: false,
                country: 'USA',
                state: 'CA',
                zip: '90210',
                description: 'High-quality gadget.',
                link: 'http://example.com',
                image_link: 'http://example.com/img.jpg',
                additional_image_link: 'http://example.com/extra_img.jpg',
                model_3d_link: 'http://example.com/model_3d',
                lifestyle_image_link: 'http://example.com/lifestyle_img.jpg',
                availability: 'In Stock',
                availability_date: '2024-06-01',
                expiration_date: '2024-12-31',
                sale_price: 79.99,
                sale_price_effective_date: '2024-05-01',
                mpn: 'MPN123',
                gtin: '123456789012'
            }
        };
    }
}
