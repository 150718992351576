import type { ICreativeComponent } from 'components';
import type { IBidRequest } from 'models';
import type { IRequestMiddleware } from './IRequestMiddleware';

/**
 * Basic request middleware that sets the site and app properties of the request. These properties are not required by
 * OpenRTB 2.6, but the surfside bidder does require them.
 */
export class BasicRequestMiddleware implements IRequestMiddleware {
    async interceptRequest(
        request: IBidRequest,
        component: ICreativeComponent
    ): Promise<IBidRequest> {
        request.site = request.site ?? {
            id: component.siteId,
            name: window.location.hostname,
            domain: window.location.hostname
        };
        request.app = request.app ?? {
            id: component.siteId,
            name: window.location.hostname,
            domain: window.location.hostname,
            bundle: window.location.hostname
        };
        return request;
    }
}
