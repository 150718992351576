import type {
    IAd,
    IImpression,
    IInline,
    ILinear,
    IMediaFile,
    ITrackingEvent,
    IVast,
    IVastProductData,
    IVideoClick
} from 'models';
import type { ILoggerService } from 'services';

function getChildOrThrow(logger: ILoggerService, element: Element, tagName: string): Element {
    logger.debug('getOrThrow name', tagName);
    const child = element.querySelector(tagName);
    if (child === null) {
        throw new Error(`No ${tagName} tag found`);
    }
    logger.debug('getOrThrow child ' + tagName, child);
    return child;
}

function getAttributeOrThrow(logger: ILoggerService, element: Element, attributeName: string): string {
    logger.debug('getAttributeOrThrow name', attributeName);
    const attribute = element.getAttribute(attributeName);
    if (attribute === null) {
        throw new Error(`No ${attributeName} attribute found`);
    }
    logger.debug('getAttributeOrThrow attribute ' + attributeName, attribute);
    return attribute;
}

function getNumberOrThrow(logger: ILoggerService, element: Element, attributeName: string): number {
    const num = getAttributeOrThrow(logger, element, attributeName);
    const parsed = Number(num);
    if (isNaN(parsed)) {
        throw new Error(`${attributeName} is not a number`);
    }
    return parsed;
}

function getAttribute(logger: ILoggerService, element: Element, attributeName: string): string | null {
    logger.debug('getAttribute name', attributeName);
    const attribute = element.getAttribute(attributeName);
    logger.debug('getAttribute attribute ' + attributeName, attribute);
    return attribute;
}

export function DeserializeVast(logger: ILoggerService, adm: string): IVast {
    logger.debug('DeserializeVast adm', adm);
    const doc = new DOMParser().parseFromString(adm, 'text/xml');
    logger.debug('DeserializeVast doc', doc);

    const root = doc.querySelector('VAST');
    if (root === null) {
        throw new Error('No VAST tag found');
    }
    logger.debug('DeserializeVast root', root);

    const version = getAttributeOrThrow(logger, root, 'version');

    const ads = Array.from(root.querySelectorAll('Ad'))
        .map(deserializeAd.bind(null, logger));
    logger.debug('DeserializeVast ads', ads);

    return {
        version,
        ads
    };
}

function deserializeAd(logger: ILoggerService, element: Element): IAd {
    logger.debug('deserializeAd element', element);
    const id = element.getAttribute('id');

    const inlineElement = getChildOrThrow(logger, element, 'InLine');
    const inline = deserializeInline(logger, inlineElement);

    return {
        id,
        inline
    };
}

function deserializeInline(logger: ILoggerService, element: Element): IInline {
    logger.debug('deserializeInline element', element);
    const adSystem = element.querySelector('AdSystem')?.textContent;
    if (adSystem === null || adSystem === undefined) {
        throw new Error('No AdSystem tag found in InLine');
    }
    logger.debug('deserializeInline adSystem', adSystem);

    const adTitle = element.querySelector('AdTitle')?.textContent;
    if (adTitle === null || adTitle === undefined) {
        throw new Error('No AdTitle tag found in InLine');
    }
    logger.debug('deserializeInline adTitle', adTitle);

    const impressionElement = getChildOrThrow(logger, element, 'Impression');
    const impression = deserializeImpression(logger, impressionElement);
    const creatives = Array.from(element.querySelectorAll('Linear'))
        .map(deserializeLinear.bind(null, logger));

    const extensions = Array.from(element.querySelectorAll('Extensions Extension'));
    let product: IVastProductData | undefined;
    if (extensions.length > 0) {
        const productExtension = extensions[0];
        const image = productExtension.querySelector('Image')?.textContent ?? null;
        const price = productExtension.querySelector('Price')?.textContent ?? null;
        const salePrice = productExtension.querySelector('SalePrice')?.textContent ?? null;
        const thc = productExtension.querySelector('THC')?.textContent ?? null;
        const strain = productExtension.querySelector('Strain')?.textContent ?? null;
        const cbd = productExtension.querySelector('CBD')?.textContent ?? null;
        const name = productExtension.querySelector('Name')?.textContent ?? null;
        const brandName = productExtension.querySelector('BrandName')?.textContent ?? null;
        const productType = productExtension.querySelector('ProductType')?.textContent ?? null;
        const details = productExtension.querySelector('Details')?.textContent ?? null;
        const cta = productExtension.querySelector('CTA')?.textContent ?? null;

        product = {
            image,
            price,
            salePrice,
            thc,
            strain,
            cbd,
            name,
            brandName,
            productType,
            details,
            cta,
            clickthroughUrl: null,
            winUrl: null,
            pixelUrl: null
        };
    }

    return {
        impression,
        adSystem,
        adTitle,
        creatives,
        product
    };
}

function deserializeImpression(logger: ILoggerService, element: Element): IImpression {
    logger.debug('deserializeImpression element', element);
    const id = getAttributeOrThrow(logger, element, 'id');
    const value = element.textContent;
    if (value === null) {
        throw new Error('No value found on Impression');
    }
    logger.debug('deserializeImpression value', value);

    return {
        id,
        value
    };
}

function deserializeLinear(logger: ILoggerService, element: Element): ILinear {
    logger.debug('deserializeLinear element', element);
    const duration = element.querySelector('Duration')?.textContent;
    if (duration === null || duration === undefined) {
        throw new Error('No Duration tag found in Linear');
    }
    logger.debug('deserializeLinear duration', duration);

    const trackingEvents = Array.from(element.querySelectorAll('TrackingEvents Tracking'))
        .map(deserializeTrackingEvent.bind(null, logger));
    logger.debug('deserializeLinear trackingEvents', trackingEvents);

    const mediaFiles = Array.from(element.querySelectorAll('MediaFiles MediaFile'))
        .map(deserializeMediaFile.bind(null, logger));
    logger.debug('deserializeLinear mediaFiles', mediaFiles);

    const videoClicks = Array.from(element.querySelectorAll('VideoClicks ClickThrough'))
        .map(deserializeVideoClick.bind(null, logger));
    logger.debug('deserializeLinear videoClicks', videoClicks);

    return {
        duration,
        trackingEvents,
        mediaFiles,
        videoClicks
    };
}

function deserializeTrackingEvent(logger: ILoggerService, element: Element): ITrackingEvent {
    logger.debug('deserializeTrackingEvent element', element);
    const event = getAttributeOrThrow(logger, element, 'event');
    const url = element.textContent;
    if (url === null) {
        throw new Error('No url found on Tracking');
    }
    logger.debug('deserializeTrackingEvent url', url);

    return {
        event,
        url
    };
}

function deserializeMediaFile(logger: ILoggerService, element: Element): IMediaFile {
    logger.debug('deserializeMediaFile element', element);
    const id = element.getAttribute('id');
    logger.debug('deserializeMediaFile id', id);

    const delivery = getAttributeOrThrow(logger, element, 'delivery');
    const type = getAttributeOrThrow(logger, element, 'type');
    const width = getNumberOrThrow(logger, element, 'width');
    const height = getNumberOrThrow(logger, element, 'height');
    const bitrate = getAttribute(logger, element, 'bitrate');
    const url = element.textContent;
    if (url === null) {
        throw new Error('No url found on MediaFile');
    }
    logger.debug('deserializeMediaFile url', url);
    return {
        id,
        delivery,
        type,
        width,
        height,
        bitrate,
        url
    };
}

function deserializeVideoClick(logger: ILoggerService, element: Element): IVideoClick {
    logger.debug('deserializeVideoClick element', element);
    const id = element.getAttribute('id');
    logger.debug('deserializeVideoClick id', id);

    const url = element.textContent;
    if (url === null) {
        throw new Error('No url found on Clickthrough');
    }
    logger.debug('deserializeVideoClick url', url);

    return {
        id,
        url
    };
}
